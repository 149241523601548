// General
import "./location.scss";
import { useState, useEffect, useRef } from "react";
// Services
import { useLazyGetProfileQuery } from "../../../../../services/data.service";
// Static Data
import userConst from "../../../../../const/userConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateLocationFormattedAddress,
  updateLocationLatitude,
  updateLocationLongitude,
  updateLocationCity,
  updateLocationState,
  updateLocationCountry,
  updateLocationCountryIso,
  updateLocation,
  updateLocationData,
} from "../../../../../redux/store/searchFilterStore";
import { updateGoogleMapsLoaded } from "../../../../../redux/store/noResetStore";
// Material UI
import { TextField, IconButton } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import ReplayIcon from "@mui/icons-material/Replay";
import ClearIcon from "@mui/icons-material/Clear";
// React Google Maps/api
import { Autocomplete } from "@react-google-maps/api";
// i18next
import { useTranslation } from "react-i18next";
// Components
import PremiumLock from "../../../elements/premium-lock/premium-lock";

const Location = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();

  // General variables
  const [searchResult, setSearchResult] = useState();
  const [localLocation, setLocalLocation] = useState("");
  const [locationLoading, setLocationLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [subTimeoutId, setSubTimeoutId] = useState(null);
  const targetTextFieldRef = useRef(null);
  const overlayTextFieldRef = useRef(null);

  // Redux variables
  const locationFormattedAddress = useSelector(
    (state) => state.searchFilter.locationFormattedAddress
  );
  const googleMapsLoaded = useSelector(
    (state) => state.noReset.googleMapsLoaded
  );
  const isDaddy = useSelector((state) => state.user.isDaddy);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Lifecycle | Mounted
  useEffect(() => {
    getProfile(null, true);
  }, []);

  // Event Handlers | Button
  const onClearInput = () => {
    dispatch(updateLocationFormattedAddress(""));
  };
  const onReloadGoogleMaps = () => {
    window.location.reload(true);
  };

  // Event Handlers | MUI Text Fields
  const onOverlayInputChange = (event) => {
    setLocalLocation(event.target.value);
    setLocationLoading(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    setTimeoutId(
      setTimeout(() => {
        dispatch(updateLocationFormattedAddress(event.target.value));

        if (!event.target.value) {
          dispatch(updateLocationLatitude(null));
          dispatch(updateLocationLongitude(null));
          dispatch(updateLocationCity(null));
          dispatch(updateLocationState(null));
          dispatch(updateLocationCountry(null));
          dispatch(updateLocationCountryIso(null));
          dispatch(updateLocation(""));
        }

        if (subTimeoutId) {
          clearTimeout(subTimeoutId);
          setSubTimeoutId(null);
        }

        setTimeout(() => {
          if (targetTextFieldRef.current) {
            targetTextFieldRef.current.focus();
            setLocationLoading(false);
          }
        }, 500);
      }, 1000)
    );
  };
  const onInputChange = (event) => {
    if (overlayTextFieldRef.current) {
      setLocalLocation(event.target.value);
      overlayTextFieldRef.current.focus();
    }
  };
  const onClearLocation = () => {
    setLocalLocation("");
    dispatch(updateLocationFormattedAddress(""));
  };

  // Event Handlers | Google Maps Autocomplete
  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      let location_data = {};

      if (place?.address_components) {
        for (let i = 0; i < place?.address_components?.length; i++) {
          if (place?.address_components[i]?.types?.length > 0) {
            switch (place?.address_components[i]?.types[0]) {
              case "locality":
                location_data.city = place?.address_components[i]?.long_name;
                break;
              case "neighborhood":
                if (location_data.city !== null) {
                  location_data.neighborhood =
                    place?.address_components[i]?.long_name;
                }
                break;
              case "administrative_area_level_1":
                location_data.state = place?.address_components[i]?.short_name;
                break;
              case "country":
                location_data.country = place?.address_components[i]?.long_name;
                location_data.country_iso =
                  place?.address_components[i]?.short_name;
                break;
              default:
                break;
            }
          }
        }
      }

      const latLng = {
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
      };

      // TBD | Deprecate soon
      dispatch(updateLocation(latLng));
      dispatch(updateLocationData(location_data));

      // New
      dispatch(updateLocationFormattedAddress(place?.formatted_address));
      dispatch(updateLocationLatitude(place?.geometry?.location?.lat()));
      dispatch(updateLocationLongitude(place?.geometry?.location?.lng()));
      dispatch(updateLocationCity(location_data?.city || null));
      dispatch(updateLocationState(location_data?.state || null));
      dispatch(updateLocationCountry(location_data?.country || null));
      dispatch(updateLocationCountryIso(location_data?.country_iso || null));
      setLocalLocation(place?.formatted_address);
    }
  };
  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  // Utility Functions
  const shouldShowPremiumLock = () => {
    if (
      getProfileData?.data?.membership_type === userConst.membershipType.free
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (googleMapsLoaded) {
    return (
      <div id="search-filter-location-subcomponent">
        <div className="filter-padding-container">
          <PlaceIcon className="pin-icon" />

          <div
            className={`location-form-container ${
              shouldShowPremiumLock() ? "locked" : ""
            }`}
          >
            <Autocomplete
              className="autocomplete-wrapper"
              onPlaceChanged={onPlaceChanged}
              onLoad={onLoad}
            >
              <TextField
                id="searchTextField"
                className="form-field"
                inputRef={targetTextFieldRef}
                onChange={onInputChange}
                value={locationFormattedAddress}
                disabled={shouldShowPremiumLock()}
                placeholder={t("filter.location")}
                variant="standard"
                InputProps={{ disableUnderline: true }}
                type="search"
              ></TextField>
            </Autocomplete>

            <div
              className={`overlay-form-field-container ${
                shouldShowPremiumLock() ? "" : "full-width"
              }`}
            >
              <TextField
                sx={{ "& .MuiSelect-select": { marginTop: "6px" } }}
                className="overlay-form-field"
                inputRef={overlayTextFieldRef}
                onChange={onOverlayInputChange}
                value={localLocation}
                disabled={shouldShowPremiumLock()}
                variant="standard"
                placeholder={t("profile.enter_a_location")}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <IconButton onClick={onClearLocation}>
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              ></TextField>
            </div>

            {shouldShowPremiumLock() && <PremiumLock />}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="search-filter-location-subcomponent">
        <div className="reload-location-container" onClick={onReloadGoogleMaps}>
          <ReplayIcon className="reload-icon" />
          <div className="reload-location-label">Location error. Reload</div>
        </div>
      </div>
    );
  }
};

export default Location;
