// General
import "./photos.scss";
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useUploadUserPhotoMutation,
  useUpdateJourneyMutation,
} from "../../../../services/data.service";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateProfilePhoto,
  updateJourneyNavigationPassthrough,
  updateProfilePhotoDataPassthrough,
  updateJourneyDataPassthrough,
  updateCroppedImage,
  updateCroppedImageMetadata,
} from "../../../../redux/store/registrationStore";
import {
  updateGeneralLoadingDialog,
  updatePhotoCropDialog,
} from "../../../../redux/store/dialogStore";
// browser-image-compression
import imageCompression from "browser-image-compression";
// react-gtm-module
import TagManager from "react-gtm-module";
// Material UI
import { useMediaQuery, Button } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import Spinner from "../../../shared/elements/spinner/spinner";

const PhotosDetails = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    uploadUserPhoto,
    {
      data: uploadUserPhotoData,
      error: uploadUserPhotoErrorData,
      isLoading: uploadUserPhotoLoading,
      isSuccess: uploadUserPhotoSuccess,
      isError: uploadUserPhotoError,
    },
  ] = useUploadUserPhotoMutation();
  const [
    updateJourney,
    {
      data: updateJourneyData,
      error: updateJourneyErrorData,
      isLoading: updateJourneyLoading,
      isSuccess: updateJourneySuccess,
      isError: updateJourneyError,
    },
  ] = useUpdateJourneyMutation();

  // Redux variables
  const profilePhoto = useSelector((state) => state.registration.profilePhoto);
  const croppedImage = useSelector((state) => state.registration.croppedImage);
  const isCompleteJourneyLoading = useSelector(
    (state) => state.registration.isCompleteJourneyLoading
  );
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 720px)");

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Lifecycle | Mounted
  useEffect(() => {
    if (!profilePhoto) {
      getProfile(null, false);
    }
  }, []);

  // Lifecycle | Check for update | Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
    } else if (getProfileSuccess) {
      switch (getProfileData?.status) {
        case 0:
        case 1:
          dispatch(
            updateProfilePhoto(
              getProfileData?.data?.profile_photo?.original_photo ||
                getProfileData?.data?.public_photos[0]?.original_photo
            )
          );
          break;
        default:
          break;
      }
    } else if (getProfileError) {
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Upload User Photo API Response
  useEffect(() => {
    if (uploadUserPhotoLoading) {
    } else if (uploadUserPhotoSuccess) {
      switch (uploadUserPhotoData?.status) {
        case 0:
        // Not sure
        case 1:
          dispatch(updateProfilePhotoDataPassthrough(uploadUserPhotoData));

          const obj = {
            journey_step: "16",
            journey_data: {
              looking_for: {},
            },
          };
          updateJourney(obj);
          break;
        default:
          break;
      }
    } else if (uploadUserPhotoLoading) {
    }
  }, [uploadUserPhotoLoading, uploadUserPhotoSuccess, uploadUserPhotoError]);

  // Lifecycle | Check for update | updateJourney API Response
  useEffect(() => {
    if (updateJourneyLoading) {
    } else if (updateJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(updateJourneyData));
      dispatch(updateJourneyNavigationPassthrough({}));
    } else if (updateJourneyError) {
    }
  }, [updateJourneyLoading, updateJourneySuccess, updateJourneyError]);

  // Event Handlers | Button
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    dispatch(updateGeneralLoadingDialog(true));

    const options = {
      maxSizeMB: 1,
    };

    if (file !== undefined) {
      try {
        const compressedFile = await imageCompression(file, options);
        const imageData = await readFile(compressedFile);
        dispatch(updateProfilePhoto(imageData));

        // dispatch(updateCroppedImage(imageData));
        // dispatch(updatePhotoCropDialog(true));

        // const obj = {
        //   photos: [
        //     {
        //       is_private: false,
        //       // base64: imageData.image,
        //       base64: null,
        //     },
        //   ],
        // };
        // dispatch(updateCroppedImageMetadata(obj));

        dispatch(updateGeneralLoadingDialog(false));
      } catch (error) {
        console.log(error);
        dispatch(updateGeneralLoadingDialog(false));
      }
    }

    dispatch(updateGeneralLoadingDialog(false));
  };
  const onNextPage = () => {
    if (!isFormValid()) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-62-62.16-62.16.1",
      },
    });

    const obj = {
      photos: [
        {
          is_private: false,
          // base64: croppedImage || profilePhoto,
          base64: profilePhoto,
        },
      ],
    };
    uploadUserPhoto(obj);
  };

  // Utility Functions
  const isFormValid = () => {
    return profilePhoto && !uploadUserPhotoLoading && !isCompleteJourneyLoading;

    return (
      (croppedImage || profilePhoto) &&
      !uploadUserPhotoLoading &&
      !isCompleteJourneyLoading
    );
  };
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div id="details-signup-photos-details-subcomponent">
      <div className="top-container">
        <div className="photos-details-label">
          {t("registration.photos.title")}
        </div>

        <div className="photos-details-description">
          <Trans
            i18nKey={"registration.photos.description"}
            components={{ br: <br /> }}
          />
        </div>

        <div className="photos-body-container">
          <Button
            className="profile-img-button"
            disableRipple
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={onFilePicker}
            />
            {profilePhoto ? (
              <img className="profile-img" src={profilePhoto} alt="default" />
            ) : (
              <div className="select-image-container">
                <div className="placeholder-image-container">
                  <AddRoundedIcon className="add-icon" />
                  {getIcon("registrationUploadPhotoIcon", "select-image-icon")}
                </div>

                <div className="select-image-label">
                  {t("registration.photos.drop_image_description")}
                </div>
              </div>
            )}
          </Button>
        </div>
      </div>

      <div className="photos-notice">
        <Trans
          i18nKey={"registration.photos.footer"}
          components={{ br: <br /> }}
        />
      </div>

      <div className="bottom-container">
        <div
          className={`continue-button ${
            isMobile ? "full-width" : "fit-content"
          } ${isFormValid() ? "" : "disabled-button"}`}
          onClick={onNextPage}
        >
          {uploadUserPhotoLoading || isCompleteJourneyLoading ? (
            <Spinner size={19} isPadding={false} color={"white-spinner"} />
          ) : (
            t("registration.photos.upload")
          )}
        </div>
      </div>
    </div>
  );
};

export default PhotosDetails;
