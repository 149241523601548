// General
import { useEffect } from "react";
// Services
import {
  useLazyGetProfileQuery,
  useLazyGetCardDetailsQuery,
  useSubmitNewCardTokenMutation,
  usePurchaseProductMutation,
  usePostPaymentTagMutation,
} from "../../../services/data.service";
// Static Data
import paymentConst from "../../../const/paymentConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCardDetail,
  updatePaymentErrorInfo,
  updatePaymentState,
  updateSecurionPayTokenDetails,
} from "../../../redux/store/paymentStore";
import { updateVideoCallPrejoinPaymentPassthrough } from "../../../redux/store/privateCallStore";
import {
  updateAddCardDialog,
  updateAddPaymentMethodDialog,
  updateBuyCoinsDialog,
  updatePaymentProcessDialog,
  updatePaymentErrorDialog,
  updateThankYouForPurchaseDialog,
  updateCoinsPackageDialog,
} from "../../../redux/store/dialogStore";
import { updateErrorToast } from "../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// Custom Hooks
import useCustomNavigate from "./useCustomNavigate-hook";

const useSecurionCreateCardAndPayFlow = () => {
  // API variables
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    getCardDetails,
    {
      data: getCardDetailsData,
      error: getCardDetailsErrorData,
      isFetching: getCardDetailsFetching,
      isLoading: getCardDetailsLoading,
      isSuccess: getCardDetailsSuccess,
      isError: getCardDetailsError,
    },
  ] = useLazyGetCardDetailsQuery();
  const [
    submitNewCardToken,
    {
      data: submitNewCardTokenData,
      error: submitNewCardTokenErrorData,
      isLoading: submitNewCardTokenLoading,
      isSuccess: submitNewCardTokenSuccess,
      isError: submitNewCardTokenError,
    },
  ] = useSubmitNewCardTokenMutation();
  const [
    postPaymentTag,
    {
      data: postPaymentTagData,
      error: postPaymentTagErrorData,
      isLoading: postPaymentTagLoading,
      isSuccess: postPaymentTagSuccess,
      isError: postPaymentTagError,
    },
  ] = usePostPaymentTagMutation();
  const [
    purchaseProduct,
    {
      data: purchaseProductData,
      error: purchaseProductErrorData,
      isLoading: purchaseProductLoading,
      isSuccess: purchaseProductSuccess,
      isError: purchaseProductError,
    },
  ] = usePurchaseProductMutation();

  // Redux variables
  const cardDetail = useSelector((state) => state.payment.cardDetail);
  const securionPayTokenDetails = useSelector(
    (state) => state.payment.securionPayTokenDetails
  );
  const selectedCoinPackage = useSelector(
    (state) => state.products.selectedCoinPackage
  );
  const dispatch = useDispatch();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Get Card Details API Response
  useEffect(() => {
    if (getCardDetailsFetching || getCardDetailsLoading) {
    } else if (getCardDetailsSuccess) {
      // Close loading dialog
      dispatch(updateAddCardDialog(false));

      if (getCardDetailsData?.status === 0) {
        // Update Card List to Store
        dispatch(updateCardDetail(getCardDetailsData?.data));

        // Update Payment State to Store
        const paymentObj = {
          state: paymentConst.paymentState.addCardSaved,
          token: securionPayTokenDetails.id,
        };
        dispatch(updatePaymentState(paymentObj));

        // Route to Coin Page
        // onNavigate(-1);

        // Close Add Payment Method Dialog
        dispatch(updateAddPaymentMethodDialog(false));

        // Open Coin Package Dialog
        dispatch(updateBuyCoinsDialog(true));

        submitPayment(getCardDetailsData?.data);
      }
    } else if (getCardDetailsError) {
    }
  }, [
    getCardDetailsFetching,
    getCardDetailsLoading,
    getCardDetailsSuccess,
    getCardDetailsError,
  ]);

  // Lifecycle | Check for update | Submit New Card Token API Response
  useEffect(() => {
    if (submitNewCardTokenLoading) {
    } else if (submitNewCardTokenSuccess) {
      getCardDetails("create-card-and-pay", false);
    } else if (submitNewCardTokenError) {
      // Close loading dialog
      dispatch(updateAddCardDialog(false));
    }
  }, [
    submitNewCardTokenLoading,
    submitNewCardTokenSuccess,
    submitNewCardTokenError,
  ]);

  // Lifecycle | Check for update | Purchase Product API Response
  useEffect(() => {
    if (purchaseProductLoading) {
    } else if (purchaseProductSuccess) {
      // Close loading dialog
      dispatch(updatePaymentProcessDialog(false));

      switch (purchaseProductData?.status) {
        case 0:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-CoinPurchase-success", // Do not change this, it will affect GTM and Insider
              category: "Purchase",
              action: "Quick Purchase Coins Actions",
              label: "Quick Purchase Coins Payment State - success",
              value: {},
              currency: purchaseProductData?.data?.paid_currency,
              product_name: purchaseProductData?.data?.product_description,
              user_id: getProfileData?.data?.id_int,
              username: getProfileData?.data?.username,
              amount: purchaseProductData?.data?.paid_amount,
            },
          });

          // Close buy coin dialog
          dispatch(updateBuyCoinsDialog(false));
          // Close coins package dialog
          dispatch(updateCoinsPackageDialog(false));
          // Open thank you dialog
          dispatch(updateThankYouForPurchaseDialog(true));

          // Notify Pre Join API to refetch data
          dispatch(updateVideoCallPrejoinPaymentPassthrough({}));

          const obj = {
            payment_tag: "pwa-coin-wallet",
            trans_id: purchaseProductData?.data?.order_id,
          };
          postPaymentTag(obj);
          break;
        case -1:
          const errorObj = {
            title: purchaseProductData?.data?.default_error_message?.title,
            message: purchaseProductData?.message,
            subtitle:
              purchaseProductData?.data?.default_error_message?.sub_title,
            solutions:
              purchaseProductData?.data?.default_error_message?.solutions,
            cta: purchaseProductData?.data?.default_error_message?.cta,
          };
          dispatch(updatePaymentErrorInfo(errorObj));

          // Open payment error dialog
          dispatch(updatePaymentErrorDialog(true));
          break;
        default:
          break;
      }

      if (purchaseProductData?.status === 0) {
        // Close buy coin dialog
        dispatch(updateBuyCoinsDialog(false));
        // Close coins package dialog
        dispatch(updateCoinsPackageDialog(false));
        // Open thank you dialog
        dispatch(updateThankYouForPurchaseDialog(true));

        // Notify Pre Join API to refetch data
        dispatch(updateVideoCallPrejoinPaymentPassthrough({}));
        const obj = {
          payment_tag: "pwa-coin-wallet",
          trans_id: purchaseProductData?.data?.order_id,
        };
        postPaymentTag(obj);
      } else if (purchaseProductData?.status === -1) {
      }
    } else if (purchaseProductError) {
    }
  }, [purchaseProductLoading, purchaseProductSuccess, purchaseProductError]);

  // Main Function
  const securionCreateCardAndPayFlow = () => {
    addCard();
  };

  // Part 1 | Call SecurionPay that we are going to make a custom form payment
  const addCard = () => {
    // Open loading dialog
    dispatch(updateAddCardDialog(true));

    const form = document.getElementById("payment-form");

    // Update Payment State to Store
    const paymentObj = {
      attempt: true,
      state: paymentConst.paymentState.addingCard, // pendingToken
      resultMessage: null,
      token: null,
    };
    dispatch(updatePaymentState(paymentObj));

    Securionpay.setPublicKey(process.env["REACT_APP_SP_PUBLIC_KEY"]);
    Securionpay.createCardToken(form, createCardTokenCallback);

    // Prevent default form submission
    return false;
  };

  // Part 2 | SecurionPay informs if payment attempt is available
  const createCardTokenCallback = (token) => {
    dispatch(updateSecurionPayTokenDetails(token));

    if (token?.error) {
      // Close loading dialog
      dispatch(updateAddCardDialog(false));

      // Display error message
      // re-enable form submit button
      const toastObj = {
        message: token?.error?.message,
        autoClose: 3000,
      };
      dispatch(updateErrorToast(toastObj));

      // Update Payment State to Store
      const paymentObj = {
        attempt: false,
        state: paymentConst.paymentState.addCardFailed,
        resultMessage: token.error.message,
        token: null,
      };
      dispatch(updatePaymentState(paymentObj));
    } else {
      // PENDING Add Card into Backend
      submitFormToBackend(token);
    }
  };

  // Part 3 | Submit form to backend
  const submitFormToBackend = async (token) => {
    let params = {
      payment_token: token.id,
    };

    submitNewCardToken(params);
  };

  // Part 4 | Attempt 3DS with card_id
  const submitPayment = (cardDetails) => {
    // Open loading dialog
    dispatch(updatePaymentProcessDialog(true));

    // Update Payment State to Store
    const paymentObj = {
      state: paymentConst.paymentState.pendingSecure3d,
      token: securionPayTokenDetails.id,
    };
    dispatch(updatePaymentState(paymentObj));

    const param3Ds = {
      amount:
        selectedCoinPackage?.discounted_amount
          ?.toFixed(2)
          .toString()
          .replace(/\./g, "") ??
        selectedCoinPackage?.amount?.toFixed(2).toString().replace(/\./g, ""),
      currency: selectedCoinPackage?.currency,
      card: cardDetails[0]?.card_id,
    };

    // Open frame with 3D Secure process
    Securionpay.setPublicKey(process.env["REACT_APP_SP_PUBLIC_KEY"]);
    Securionpay.verifyThreeDSecure(param3Ds, verifyThreeDSecureCallback);
  };

  // Part 5 | Attempt 3DSecure when SecurionPay says good.
  const verifyThreeDSecureCallback = (token) => {
    if (token?.error) {
      // Close loading dialog
      dispatch(updatePaymentProcessDialog(false));

      // display error message
      // re-enable form submit button

      // Update Payment State to Store
      const paymentObj = {
        attempt: false,
        state: paymentConst.paymentState.decline,
        resultMessage: token.error.message,
        token: null,
      };
      dispatch(updatePaymentState(paymentObj));
    } else {
      // re-enable form submit button

      // Update Payment State to Store
      const paymentObj = {
        state: paymentConst.paymentState.pendingBackendSubmission,
        token: token.id,
      };
      dispatch(updatePaymentState(paymentObj));

      const purchaseObj = {
        payment_gateway: "securionpay",
        payment_token: token.id,
        product_hash_id: selectedCoinPackage?.product_hash_id,
      };
      purchaseProduct(purchaseObj);
    }
  };

  return securionCreateCardAndPayFlow;
};

export default useSecurionCreateCardAndPayFlow;
