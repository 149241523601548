// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Public Photos variables
  profilePhoto: null,
  publicPhotos: [],

  // Private Photos variables
  privatePhotos: [],

  // Cropped Image variables
  croppedImage: null,
  croppedImageMetadata: null,

  // Basic Information variables
  email: null,
  username: "",
  age: null,
  birthdate: "",

  // TOR
  tor: [],

  // Dating Styles variables
  datingStyles: [],

  // Financial Information variables
  lifestyle: "",
  networth: "",
  annualIncome: "",
  datingBudget: "",

  // Locations variables

  // Appearance variables
  heightData: [],
  heightIndex: null,
  height: "",
  bodyType: "",
  ethnicity: "",
  race: "",
  eyeColor: "",
  hairColor: "",

  // Personal Information variables
  position: "",
  industry: "",
  education: "",
  relationshipStatus: "",
  children: "",
  smoking: "",
  drinking: "",
  alcoholPreference: [],

  // About Me variables
  aboutMeManualTextStatus: null,
  aboutMeManualText: "",
  aboutMeManualTextNewContent: "",
  aboutMe: null,
  aboutMeList: [],
  aboutMeLength: 0,
  personalityTraits: null,
  personalityType: null,
  starSign: null,
  dominantOrSubmissive: null,
  fitnessLevel: null,
  hobbies: null,

  // Looking For variables
  lookingForAgeRange: {
    min: null,
    max: null,
  },
  lookingForLocation: [],
  lookingForEthnicityRace: [],

  location: {
    lat: null,
    lng: null,
    location_data: {
      city: null,
      state: null,
      formatted_address: "",
      country: null,
      country_iso: null,
    },
    type: "user_defined",
  },
  lookingFor: "",
  tagline: "", // Deprecated
  occupation: "",

  // Status variables
  usernameStatus: null,
  usernameNewContent: null,

  // Utility variables
  updateProfileDialogView: null,
  updateProfileFullscreenTab: 0,
  profileCompletionState: null,
  isUnsavedChanges: false,
  isEditAboutMeNext: null,
};

export const editProfileSlice = createSlice({
  name: "editProfile",
  initialState,
  reducers: {
    // Cropped Image Functions
    updateCroppedImage: (state, action) => {
      state.croppedImage = action.payload;
    },
    updateCroppedImageMetadata: (state, action) => {
      state.croppedImageMetadata = action.payload;
    },

    // Basic Information Functions
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updateUsername: (state, action) => {
      state.username = action.payload;
    },
    updateAge: (state, action) => {
      state.age = action.payload;
    },
    updateBirthdate: (state, action) => {
      state.birthdate = action.payload;
    },

    // TOR Functions
    updateTor: (state, action) => {
      state.tor = action.payload;
    },

    // Dating Styles Functions
    updateDatingStyles: (state, action) => {
      state.datingStyles = action.payload;
    },

    // Financial Information Functions
    updateLifestyle: (state, action) => {
      state.lifestyle = action.payload;
    },
    updateNetworth: (state, action) => {
      state.networth = action.payload;
    },
    updateAnnualIncome: (state, action) => {
      state.annualIncome = action.payload;
    },
    updateDatingBudget: (state, action) => {
      state.datingBudget = action.payload;
    },

    // Appearance Functions
    updateHeightData: (state, action) => {
      state.heightData = action.payload;
    },
    updateHeightIndex: (state, action) => {
      state.heightIndex = action.payload;
    },
    updateHeight: (state, action) => {
      state.height = action.payload;
    },
    updateBodyType: (state, action) => {
      state.bodyType = action.payload;
    },
    updateEthnicity: (state, action) => {
      state.ethnicity = action.payload;
    },
    updateRace: (state, action) => {
      state.race = action.payload;
    },
    updateEyeColor: (state, action) => {
      state.eyeColor = action.payload;
    },
    updateHairColor: (state, action) => {
      state.hairColor = action.payload;
    },

    // Personal Information Functions
    updatePosition: (state, action) => {
      state.position = action.payload;
    },
    updateIndustry: (state, action) => {
      state.industry = action.payload;
    },
    updateEducation: (state, action) => {
      state.education = action.payload;
    },
    updateRelationshipStatus: (state, action) => {
      state.relationshipStatus = action.payload;
    },
    updateSmoking: (state, action) => {
      state.smoking = action.payload;
    },
    updateDrinking: (state, action) => {
      state.drinking = action.payload;
    },
    updateAlcoholPreference: (state, action) => {
      state.alcoholPreference = action.payload;
    },

    // About Me Functions
    updateAboutMeManualTextStatus: (state, action) => {
      state.aboutMeManualTextStatus = action.payload;
    },
    updateAboutMeManualText: (state, action) => {
      state.aboutMeManualText = action.payload;
    },
    updateAboutMeManualTextNewContent: (state, action) => {
      state.aboutMeManualTextNewContent = action.payload;
    },
    updateAboutMe: (state, action) => {
      state.aboutMe = action.payload;
    },
    updateAboutMeList: (state, action) => {
      state.aboutMeList = action.payload;
    },
    updateAboutMeLength: (state, action) => {
      state.aboutMeLength = action.payload;
    },
    updatePersonalityTraits: (state, action) => {
      state.personalityTraits = action.payload;
    },
    updatePersonalityType: (state, action) => {
      state.personalityType = action.payload;
    },
    updateStarSign: (state, action) => {
      state.starSign = action.payload;
    },
    updateDominantOrSubmissive: (state, action) => {
      state.dominantOrSubmissive = action.payload;
    },
    updateFitnessLevel: (state, action) => {
      state.fitnessLevel = action.payload;
    },
    updateHobbies: (state, action) => {
      state.hobbies = action.payload;
    },

    // Looking For Functions
    updateLookingForAgeRange: (state, action) => {
      state.lookingForAgeRange = action.payload;
    },
    updateLookingForLocation: (state, action) => {
      state.lookingForLocation = action.payload;
    },
    updateLookingForEthnicityRace: (state, action) => {
      state.lookingForEthnicityRace = action.payload;
    },

    updateProfilePhoto: (state, action) => {
      state.profilePhoto = action.payload;
    },
    updatePublicPhotos: (state, action) => {
      state.publicPhotos = action.payload;
    },
    updatePrivatePhotos: (state, action) => {
      state.privatePhotos = action.payload;
    },

    updateLookingFor: (state, action) => {
      state.lookingFor = action.payload;
    },
    updateTagline: (state, action) => {
      state.tagline = action.payload;
    },

    updateOccupation: (state, action) => {
      state.occupation = action.payload;
    },

    updateChildren: (state, action) => {
      state.children = action.payload;
    },

    // Status Functions
    updateUsernameStatus: (state, action) => {
      state.usernameStatus = action.payload;
    },
    updateUsernameNewContent: (state, action) => {
      state.usernameNewContent = action.payload;
    },

    // Location Functions
    updateLocation: (state, action) => {
      state.location = action.payload;
    },
    updateLocationFormattedAddress: (state, action) => {
      state.location.location_data.formatted_address = action.payload;
    },
    resetLocation: (state) => {
      state.location = {
        lat: null,
        lng: null,
        location_data: {
          city: null,
          state: null,
          formatted_address: "",
          country: null,
          country_iso: null,
        },
        type: "user_defined",
      };
    },

    // Utility Functions
    resetEditProfileStore: () => {
      return { ...initialState };
    },
    updateUpdateProfileDialogView: (state, action) => {
      state.updateProfileDialogView = action.payload;
    },
    updateUpdateProfileFullscreenTab: (state, action) => {
      state.updateProfileFullscreenTab = action.payload;
    },
    updateProfileCompletionState: (state, action) => {
      state.profileCompletionState = action.payload;
    },
    updateIsUnsavedChanges: (state, action) => {
      state.isUnsavedChanges = action.payload;
    },
    updateIsEditAboutMeNext: (state, action) => {
      state.isEditAboutMeNext = action.payload;
    },
  },
});

export const {
  // General Functions
  updateEmail,

  // Cropped Image Functions
  updateCroppedImage,
  updateCroppedImageMetadata,

  // TOR Functions
  updateTor,

  // Dating Styles Functions
  updateDatingStyles,

  // Financial Information Functions
  updateLifestyle,
  updateNetworth,
  updateAnnualIncome,
  updateDatingBudget,

  // Appearance Functions
  updateHeightData,
  updateHeightIndex,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateRace,
  updateEyeColor,
  updateHairColor,

  // Personal Information Functions
  updatePosition,
  updateIndustry,
  updateEducation,
  updateRelationshipStatus,
  updateChildren,
  updateSmoking,
  updateDrinking,
  updateAlcoholPreference,

  // About Me Functions
  updateAboutMeManualTextStatus,
  updateAboutMeManualText,
  updateAboutMeManualTextNewContent,
  updateAboutMe,
  updateAboutMeList,
  updateAboutMeLength,
  updatePersonalityTraits,
  updatePersonalityType,
  updateStarSign,
  updateDominantOrSubmissive,
  updateFitnessLevel,
  updateHobbies,

  // Looking For Functions
  updateLookingForAgeRange,
  updateLookingForLocation,
  updateLookingForEthnicityRace,

  updateProfilePhoto,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateUsername,
  updateAge,
  updateBirthdate,

  updateLookingFor,
  updateTagline,

  updateOccupation,

  // Status Functions
  updateUsernameStatus,
  updateUsernameNewContent,

  // Location Functions
  updateLocation,
  updateLocationFormattedAddress,
  resetLocation,

  // Utility Functions
  resetEditProfileStore,
  updateUpdateProfileDialogView,
  updateUpdateProfileFullscreenTab,
  updateProfileCompletionState,
  updateIsUnsavedChanges,
  updateIsEditAboutMeNext,
} = editProfileSlice.actions;

export default editProfileSlice.reducer;
