// General
import "./search-page-banner-tester.scss";
import { useState, useRef } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateOnboardingPrivateRoomDaddyDialog,
  updateUpdateTorDialog,
} from "../../../../redux/store/dialogStore";
// Material UI
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Banner from "../../../shared/elements/banner/banner";

const SearchPageBannerTester = () => {
  // General variables
  const swiperRef = useRef(null);
  const [timerObj, setTimerObj] = useState([]);
  const [showTester, setShowTester] = useState(false);
  const [searchImageArray, setSearchImageArray] = useState([]);

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Event Handlers | Button
  const onShowTester = () => {
    setShowTester(!showTester);
  };
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const imageData = await readFile(file);

        setSearchImageArray((prevState) => [
          ...prevState,
          {
            id: 0,
            image: imageData,
            link: "",
            name: "",
            title: "",
            countdown_data: null,
            simple_view: false,
          },
        ]);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onRemoveBanner = (index) => {
    const timerContainers = document.getElementsByClassName(
      "tester-search-timer-container"
    );

    timerContainers[index].innerHTML = "";

    const newBannerArray = [...searchImageArray];
    newBannerArray.splice(index, 1);
    setSearchImageArray(newBannerArray);
  };
  const onSaveBanner = (index) => {
    if (searchImageArray[index].simple_view) {
      const timerContainers = document.getElementsByClassName(
        "tester-search-timer-container"
      );

      timerContainers[index].innerHTML =
        searchImageArray[index].countdown_data.copies;
    } else {
      organizeSearchTimerObj(searchImageArray);
    }
  };
  const onOpenLink = (banner) => {
    if (banner?.name === "video_call_landing") {
      dispatch(updateOnboardingPrivateRoomDaddyDialog(true));
    } else if (banner?.name === "p_20210818.premium") {
      onNavigate(routeConst.upgrade.path);
    } else if (banner?.name.includes("contest.")) {
      onNavigate(routeConst.live.path);
    } else if (banner?.name === "tor") {
      dispatch(updateUpdateTorDialog(true));
    } else if (banner?.link) {
      window.open(banner?.link, "_blank");
    }
  };

  // Event Handlers | Input
  const onInputChange = (event, index, type) => {
    let tempData = [...searchImageArray];

    switch (type) {
      case "id":
      case "link":
      case "name":
      case "title":
        tempData[index][type] = event.target.value;
        setSearchImageArray(tempData);

        organizeSearchTimerObj(tempData);
        break;
      case "copies":
      case "countdown_end_at":
      case "countdown_end_at_epoch":
      case "countdown_font_color":
      case "countdown_start_at":
      case "countdown_start_at_epoch":
      case "is_countdown_required":
      case "status":
      case "title_font_color":
        tempData[index].countdown_data[type] = event.target.value;
        setSearchImageArray(tempData);
        break;
      case "countdown_timer_text":
        tempData[index].countdown_data.copies = event.target.value;
        setSearchImageArray(tempData);
        break;
      default:
        break;
    }
  };

  // Event Handlers | Switch
  const onToggleCountdownData = (event, index) => {
    let tempData = [...searchImageArray];

    if (event.target.checked) {
      tempData[index].countdown_data = {
        copies: "",
        countdown_end_at: "",
        countdown_end_at_epoch: "",
        countdown_font_color: "#FFF000",
        countdown_start_at: "",
        countdown_start_at_epoch: "",
        is_countdown_required: false,
        status: "ongoing",
        title_font_color: "#FFF000",
      };
    } else {
      tempData[index].countdown_data = null;
    }

    setSearchImageArray(tempData);

    organizeSearchTimerObj(tempData);
  };
  const onToggleIsCountdownRequired = (event, index) => {
    let tempData = [...searchImageArray];

    tempData[index].countdown_data.is_countdown_required = event.target.checked;

    setSearchImageArray(tempData);
  };
  const onToggleSimpleView = (event, index) => {
    let tempData = [...searchImageArray];
    tempData[index].simple_view = event.target.checked;
    setSearchImageArray(tempData);
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const organizeSearchTimerObj = (banners) => {
    let newArr = [];

    banners.map((banner, index) => {
      newArr.push({
        index: index,
        hasTimer: banner?.countdown_data ? true : false,
        startEpoch: banner?.countdown_data?.countdown_start_at_epoch,
        endEpoch: banner?.countdown_data?.countdown_end_at_epoch,
        label: banner?.countdown_data?.copies,
        timerInterval: null,
        countdown: null,
      });
    });

    newArr.map((item, index) => {
      if (item.hasTimer) {
        let today = moment();
        let start = moment.unix(item.startEpoch);
        let end = moment.unix(item.endEpoch);

        item.timerInterval = setInterval(() => {
          let readableStartDate = getReadableDateFromEpoch(item.startEpoch);
          let readableEndDate = getReadableDateFromEpoch(item.endEpoch);

          // Clear Interval if Epoch is less than 0
          if (item.endEpoch < 0) {
            clearInterval(item.timerInterval);
          }

          // Set Countdown
          if (today.isBefore(start)) {
            item.countdown = `${t("common.starts_in")} ${t("common.n_day", {
              n: readableStartDate.days,
            })} ${readableStartDate.hrs}:${
              readableStartDate.mnts > 9
                ? readableStartDate.mnts
                : `0${readableStartDate.mnts}`
            }:${
              readableStartDate.seconds > 9
                ? readableStartDate.seconds
                : `0${readableStartDate.seconds}`
            }`;
          } else if (today.isAfter(end)) {
            item.countdown = item?.label;
            clearInterval(item.timerInterval);
          } else {
            item.countdown = `${t("common.ends_in")} ${t("common.n_day", {
              n: readableEndDate.days,
            })} ${readableEndDate.hrs}:${
              readableEndDate.mnts > 9
                ? readableEndDate.mnts
                : `0${readableEndDate.mnts}`
            }:${
              readableEndDate.seconds > 9
                ? readableEndDate.seconds
                : `0${readableEndDate.seconds}`
            }`;
          }

          // Set Countdown to DOM
          // Reason we use DOM injection is because Carousel does not update after it is initialized
          const timerContainers = document.getElementsByClassName(
            "tester-search-timer-container"
          );
          if (
            timerContainers.length > index &&
            item &&
            item.countdown !== undefined
          ) {
            timerContainers[index].innerHTML = item.countdown;
          }
        }, 1000);
      }
    });

    setTimerObj(newArr);
  };
  const getReadableDateFromEpoch = (epoch) => {
    // Convert Epoch to Seconds
    let epochToSeconds = epoch - moment().unix();

    // Convert Seconds to Days, Hours, Minutes, Seconds
    const days = Math.floor(epochToSeconds / (3600 * 24));
    epochToSeconds -= days * 3600 * 24;
    const hrs = Math.floor(epochToSeconds / 3600);
    epochToSeconds -= hrs * 3600;
    const mnts = Math.floor(epochToSeconds / 60);
    epochToSeconds -= mnts * 60;
    const seconds = epochToSeconds;

    return { days, hrs, mnts, seconds };
  };

  return (
    <div className="search-page-banner-container">
      <div className="page-label">
        <div
          className="search-banner-tester-icon-container"
          onClick={onShowTester}
        >
          <BuildCircleIcon />
        </div>
        <div className="search-label">Search Page</div>
      </div>

      <div className="search-page-banner">
        <Banner type={"search"} view={"carousel"} />
      </div>

      {showTester && (
        <div className="tester-container">
          <Button
            className="test-banner-button"
            disableRipple
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={onFilePicker}
            />
            <div>Upload Banner</div>
          </Button>

          <div className="search-page-banner">
            <div className="preview">Preview</div>

            <div id="banner-subcomponent">
              <div className="carousel-container border-radius">
                <swiper-container
                  ref={swiperRef}
                  class="swiper-container"
                  navigation="false"
                  pagination={searchImageArray?.length > 1 ? "true" : "false"}
                  scrollbar="false"
                  // loop="true"
                  speed="300"
                  autoplay-delay="2500"
                >
                  {searchImageArray?.map((banner, index) => (
                    <swiper-slide
                      class="banner-container"
                      onClick={() => onOpenLink(banner)}
                      key={index}
                    >
                      <img
                        className="banner-image border-radius"
                        src={banner?.image}
                      />
                      <div
                        className="tester-search-timer-container"
                        style={{
                          color: banner?.countdown_data?.countdown_font_color,
                        }}
                      ></div>
                    </swiper-slide>
                  ))}
                </swiper-container>
              </div>
            </div>

            <div>Backend Use only</div>

            <div className="banner-info-container">
              {searchImageArray?.map((banner, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {`Banner ${index + 1}`}
                  </AccordionSummary>

                  <AccordionDetails>
                    <div className="banner-data-info-container">
                      <div className="banner-data-label-container">
                        {/* ID */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.id}
                            onChange={(event) =>
                              onInputChange(event, index, "id")
                            }
                            label="id"
                            variant="outlined"
                          />
                        </div>

                        {/* Link */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.link}
                            onChange={(event) =>
                              onInputChange(event, index, "link")
                            }
                            label="link"
                            variant="outlined"
                          />
                        </div>

                        {/* Name */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.name}
                            onChange={(event) =>
                              onInputChange(event, index, "name")
                            }
                            label="name"
                            variant="outlined"
                          />
                        </div>

                        {/* Title */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.title}
                            onChange={(event) =>
                              onInputChange(event, index, "title")
                            }
                            label="title"
                            variant="outlined"
                          />
                        </div>

                        {/* Countdown Data */}
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                className="custom-switch"
                                onChange={(event) =>
                                  onToggleCountdownData(event, index)
                                }
                                label="countdown_data"
                              />
                            }
                            label="countdown_data"
                          />
                        </FormGroup>

                        {/* Simple View */}
                        {banner?.countdown_data && (
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  className="custom-switch"
                                  onChange={(event) =>
                                    onToggleSimpleView(event, index)
                                  }
                                  label="Simple View"
                                />
                              }
                              label="Simple View"
                            />
                          </FormGroup>
                        )}

                        {!banner?.simple_view && banner?.countdown_data && (
                          <div className="countdown-data-container">
                            {/* Copies */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={banner?.countdown_data?.copies}
                                onChange={(event) =>
                                  onInputChange(event, index, "copies")
                                }
                                label="copies"
                                variant="outlined"
                              />
                            </div>

                            {/* Countdown End At */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={banner?.countdown_data?.countdown_end_at}
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_end_at"
                                  )
                                }
                                label="countdown_end_at"
                                variant="outlined"
                              />
                            </div>

                            {/* Countdown End At Epoch */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={
                                  banner?.countdown_data?.countdown_end_at_epoch
                                }
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_end_at_epoch"
                                  )
                                }
                                label="countdown_end_at_epoch"
                                variant="outlined"
                              />
                            </div>

                            {/* Countdown Font Color */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={
                                  banner?.countdown_data?.countdown_font_color
                                }
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_font_color"
                                  )
                                }
                                label="countdown_font_color"
                                variant="outlined"
                              />
                            </div>

                            {/* Countdown Start At */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={
                                  banner?.countdown_data?.countdown_start_at
                                }
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_start_at"
                                  )
                                }
                                label="countdown_start_at"
                                variant="outlined"
                              />
                            </div>

                            {/* Countdown Start At Epoch */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={
                                  banner?.countdown_data
                                    ?.countdown_start_at_epoch
                                }
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_start_at_epoch"
                                  )
                                }
                                label="countdown_start_at_epoch"
                                variant="outlined"
                              />
                            </div>

                            {/* Is Countdown Required */}
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    className="custom-switch"
                                    onChange={(event) =>
                                      onToggleIsCountdownRequired(event, index)
                                    }
                                    label="is_countdown_required"
                                  />
                                }
                                label="is_countdown_required"
                              />
                            </FormGroup>

                            {/* Status */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={banner?.countdown_data?.status}
                                onChange={(event) =>
                                  onInputChange(event, index, "status")
                                }
                                label="status"
                                variant="outlined"
                              />
                            </div>

                            {/* Title Font Color */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={banner?.countdown_data?.title_font_color}
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "title_font_color"
                                  )
                                }
                                label="title_font_color"
                                variant="outlined"
                              />
                            </div>
                          </div>
                        )}

                        {banner?.simple_view && (
                          <div className="countdown-data-container">
                            {/* Copies */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={banner?.countdown_data?.copies}
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_timer_text"
                                  )
                                }
                                label="Countdown timer text"
                                variant="outlined"
                              />
                            </div>

                            {/* Countdown Font Color */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={
                                  banner?.countdown_data?.countdown_font_color
                                }
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_font_color"
                                  )
                                }
                                label="countdown_font_color"
                                variant="outlined"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="banner-data-button-container">
                        <CloseIcon
                          className="remove-button"
                          onClick={() => onRemoveBanner(index)}
                        />
                        <SaveIcon
                          className="save-button"
                          onClick={() => onSaveBanner(index)}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchPageBannerTester;
