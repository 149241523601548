// General
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Choose Your Role variables
  chooseYourRoleDialog: false,

  // Whats A Role variables
  whatsARoleDialog: false,

  // Add to homescreen variables
  saveToHomescreenDialog: false,
  saveToHomescreenTutorialDialog: false,

  // Geolocation Permission variables
  geolocationPermissionPromptDialog: false,
  geolocationPermissionDeniedDialog: false,

  // Notification Permission variables
  notificationPermissionPromptDialog: false,

  // Live Introduction variables
  liveIntroductionDialog: false,

  // Whats New variables
  whatsNewDialog: false,

  // VPN variables
  vpnBlockDialog: false,

  // Daily Check In variables
  dailyCheckInDialog: false,

  // Levels variables
  levelUpDialog: false,
  levelsDescriptionDialog: false,

  // Check in variables
  checkInStreakDialog: false,

  // Onboarding diamonds info variables
  onboardingDiamondsInfoDialog: false,

  // Onboarding video call variables
  onboardingVideoCallDialog: false,

  // Onboarding video call room variable
  onboardingVideoCallRoomDialog: false,

  // Onboarding private room variables
  onboardingPrivateRoomDaddyDialog: false,
  onboardingPrivateRoomBabyDialog: false,

  // Onboarding live streaming variables
  onboardingLiveStreamingDialog: false,

  // Upgrade to Premium variables
  upgradeToPremiumDialog: false,

  // Full Sugar Experience variables
  fullSugarExperienceDialog: false,

  // Missing TOR variables
  missingTorDialog: false,
  completeTorDialog: false,

  // Update TOR variables
  updateTorDialog: false,

  // Edit Profile variables
  updateProfileFullscreenDialog: false,
  editProfileUnsavedChangesDialog: false,

  // Deactivate variables
  deactivateAccountDialog: false,

  // User Profile variables
  userProfileDialog: false,
  userIsLiveDialog: false,

  // Profile Achievement Badge variables
  profileAchievementBadgeDialog: false,

  // Verified Caller Description variables
  verifiedCallerDescriptionDialog: false,

  // Preview Photo variables
  previewPhotoDialog: false,

  // Profile Rejected Info variables
  profileRejectedInfoDialog: false,

  // View TOR Description variables
  viewTorDescriptionDialog: false,

  // Request Private Photo variables
  requestPrivatePhotoDialog: false,

  // Report variables
  reportDialog: false,

  // Inbox variables
  clearMessagesDialog: false,

  // Video Call variables
  goPrivateAddToHomeScreenDialog: false,
  goPrivateDontSwitchAppDialog: false,
  goPrivateChecklistDialog: false,
  goPrivatePermissionDialog: false,
  goPrivatePermissionTutorialDialog: false,
  joinPrivateAndroidWarningDialog: false,
  videoCallCameraTesterDialog: false,
  restartPhoneDescriptionDialog: false,
  preJoinDialog: false,
  preJoinSugarBabyWarningDialog: false,
  busyInPrivateDialog: false,
  calleeCurrentSessionSummaryDialog: false,
  chargedMessagePopupDialog: false,
  kickUserDialog: false,
  endCallDialog: false,
  videoCallInfoDialog: false,
  privateCallCallingDialog: false,
  privateCallDeclineDialog: false,
  privateCallAcceptedDialog: false,
  privateCallWaitingRoomDialog: false,
  incomingPrivateCallDialog: false,
  videoCallErrorDialog: false,
  thankYouForReviewDialog: false,
  customizeTipRequestDialog: false,
  customizeTipRequestAcceptedDialog: false,
  outOfCoinsDialog: false,

  // Go Live variables
  goLiveInfoDialog: false,

  // Tips variables
  customizeTipsDialog: false,

  // Livestream variables
  livestreamKickedDialog: false,
  livestreamProfileViewDialog: false,
  livestreamReportDialog: false,
  livestreamReportSuccessDialog: false,
  dualPkEndResultDialog: false,

  // Casino variables
  casinoGameDialog: false,

  // Leaderboard variables
  leaderboardEventsDialog: false,
  topLeaderboardListDialog: false,

  // Streamer Center variables
  streamerCenterLearnMoreDialog: false,
  diamondsWithdrawalInfoDialog: false,

  // Product Purchase variables
  coinsPackageDialog: false,
  productPurchaseDialog: false,
  limitedTimeCoinPurchaseDialog: false,
  thankYouForPurchaseDialog: false,

  // Coins variables
  buyCoinsDialog: false,
  paymentProcessDialog: false,
  paymentErrorDialog: false,
  addPaymentMethodDialog: false,
  addCardDialog: false,

  // Info variables
  myWalletInfoDialog: false,
  diamondsInfoDialog: false,
  enableCallInfoDialog: false,
  ratesInfoDialog: false,

  // Tier Info variables
  tierInfoDialog: false,

  // PK Info variables
  pkInfoDialog: false,

  // Tutorial variables
  videoCallTutorialDialog: false,

  // Video Call Settings Tutorial variables
  videoCallSettingsTutorialDialog: false,

  // Search Filter variables
  searchFilterDialog: false,
  bodyTypeFilterDialog: false,
  drinkingFilterDialog: false,
  educationFilterDialog: false,
  ethnicityFilterDialog: false,
  eyeColorFilterDialog: false,
  hairColorFilterDialog: false,
  lifestyleFilterDialog: false,
  relationshipStatusFilterDialog: false,
  smokingFilterDialog: false,
  torFilterDialog: false,

  // Unfollow User variables
  unfollowUserDialog: false,

  // Low Power Mode variables
  lowPowerModeDialog: false,

  // Purchase Coins variables
  coinsDiscountDialog: false,

  // Camera Microphone variables
  cameraMicrophoneSettingsDialog: false,

  // Sugarbook Verified variables
  onboardingSugarbookVerifiedDialog: false,
  sugarbookVerifiedCameraDialog: false,

  // Registration variables
  registrationAboutMeContentDialog: false,
  updateRegistrationAboutMeDialog: false,
  restartRegistrationDialog: false,
  registrationProfileAlmostCompleteDialog: false,

  // General Loading variables
  generalLoadingDialog: false,
  verificationStatusDialog: false,

  // Prevent Landscape variables
  preventLandscapeDialog: false,

  // Upgrade Promo variables
  upgradePromoDialog: false,

  // Survey Monkey variables
  surveyMonkeyDialog: false,

  // Video Splash Screen variables
  videoSplashScreenDialog: false,

  // Redirect from old app variables
  redirectFromOldAppDialog: false,

  // Login Page Troubleshoot variables
  loginPageTroubleshootDialog: false,
  confirmAppResetDialog: false,

  // Email Registered variables
  emailRegisteredDialog: false,

  // Photo Crop variables
  photoCropDialog: false,

  // Testing variables
  pkRoundTabDialog: false,

  // Dialog Search Param variables
  dialogSearchParam: [],
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    // Choose Your Role Functions
    updateChooseYourRoleDialog: (state, action) => {
      state.chooseYourRoleDialog = action.payload;
    },

    // Whats A Role Functions
    updateWhatsARoleDialog: (state, action) => {
      state.whatsARoleDialog = action.payload;
    },

    // Add to homescreen Functions
    updateSaveToHomescreenDialog: (state, action) => {
      state.saveToHomescreenDialog = action.payload;
    },
    updateSaveToHomescreenTutorialDialog: (state, action) => {
      state.saveToHomescreenTutorialDialog = action.payload;
    },

    // Geolocation Permission Functions
    updateGeolocationPermissionPromptDialog: (state, action) => {
      state.geolocationPermissionPromptDialog = action.payload;
    },
    updateGeolocationPermissionDeniedDialog: (state, action) => {
      state.geolocationPermissionDeniedDialog = action.payload;
    },

    // Notification Permission Functions
    updateNotificationPermissionPromptDialog: (state, action) => {
      state.notificationPermissionPromptDialog = action.payload;
    },

    // Live Introduction Functions
    updateLiveIntroductionDialog: (state, action) => {
      state.liveIntroductionDialog = action.payload;
    },

    // Whats New Functions
    updateWhatsNewDialog: (state, action) => {
      state.whatsNewDialog = action.payload;
    },

    // VPN Functions
    updateVpnBlockDialog: (state, action) => {
      state.vpnBlockDialog = action.payload;
    },

    // Daily Check In Functions
    updateDailyCheckInDialog: (state, action) => {
      state.dailyCheckInDialog = action.payload;
    },

    // Levels Functions
    updateLevelUpDialog: (state, action) => {
      state.levelUpDialog = action.payload;
    },
    updateLevelsDescriptionDialog: (state, action) => {
      state.levelsDescriptionDialog = action.payload;
    },

    // Check in Functions
    updateCheckInStreakDialog: (state, action) => {
      state.checkInStreakDialog = action.payload;
    },

    // Onboarding diamonds info Functions
    updateOnboardingDiamondsInfoDialog: (state, action) => {
      state.onboardingDiamondsInfoDialog = action.payload;
    },

    // Onboarding video call Functions
    updateOnboardingVideoCallDialog: (state, action) => {
      state.onboardingVideoCallDialog = action.payload;
    },

    // Onboarding video call room Functions
    updateOnboardingVideoCallRoomDialog: (state, action) => {
      state.onboardingVideoCallRoomDialog = action.payload;
    },

    // Onboarding private room Functions
    updateOnboardingPrivateRoomDaddyDialog: (state, action) => {
      state.onboardingPrivateRoomDaddyDialog = action.payload;
    },
    updateOnboardingPrivateRoomBabyDialog: (state, action) => {
      state.onboardingPrivateRoomBabyDialog = action.payload;
    },

    // Onboarding live streaming Functions
    updateOnboardingLiveStreamingDialog: (state, action) => {
      state.onboardingLiveStreamingDialog = action.payload;
    },

    // Upgrade to Premium Functions
    updateUpgradeToPremiumDialog: (state, action) => {
      state.upgradeToPremiumDialog = action.payload;
    },

    // Full Sugar Experience Functions
    updateFullSugarExperienceDialog: (state, action) => {
      state.fullSugarExperienceDialog = action.payload;
    },

    // Missing TOR Functions
    updateMissingTorDialog: (state, action) => {
      state.missingTorDialog = action.payload;
    },
    updateCompleteTorDialog: (state, action) => {
      state.completeTorDialog = action.payload;
    },

    // Update TOR Functions
    updateUpdateTorDialog: (state, action) => {
      state.updateTorDialog = action.payload;
    },

    // Edit Profile Dialog
    updateUpdateProfileFullscreenDialog: (state, action) => {
      state.updateProfileFullscreenDialog = action.payload;
    },
    updateEditProfileUnsavedChangesDialog: (state, action) => {
      state.editProfileUnsavedChangesDialog = action.payload;
    },

    // Deactivate Functions
    updateDeactivateAccountDialog: (state, action) => {
      state.deactivateAccountDialog = action.payload;
    },

    // User Profile Functions
    updateUserProfileDialog: (state, action) => {
      state.userProfileDialog = action.payload;
    },
    updateUserIsLiveDialog: (state, action) => {
      state.userIsLiveDialog = action.payload;
    },

    // Profile Achievement Badge Functions
    updateProfileAchievementBadgeDialog: (state, action) => {
      state.profileAchievementBadgeDialog = action.payload;
    },

    // Verified Caller Description Functions
    updateVerifiedCallerDescriptionDialog: (state, action) => {
      state.verifiedCallerDescriptionDialog = action.payload;
    },

    // Preview Photo Functions
    updatePreviewPhotoDialog: (state, action) => {
      state.previewPhotoDialog = action.payload;
    },

    // Profile Rejected Info Functions
    updateProfileRejectedInfoDialog: (state, action) => {
      state.profileRejectedInfoDialog = action.payload;
    },

    // View TOR Description Functions
    updateViewTorDescriptionDialog: (state, action) => {
      state.viewTorDescriptionDialog = action.payload;
    },

    // Request Private Photo Functions
    updateRequestPrivatePhotoDialog: (state, action) => {
      state.requestPrivatePhotoDialog = action.payload;
    },

    // Report Functions
    updateReportDialog: (state, action) => {
      state.reportDialog = action.payload;
    },

    // Clear Messages Functions
    updateClearMessagesDialog: (state, action) => {
      state.clearMessagesDialog = action.payload;
    },

    // Video Call Functions
    updateGoPrivateAddToHomeScreenDialog: (state, action) => {
      state.goPrivateAddToHomeScreenDialog = action.payload;
    },
    updateGoPrivateDontSwitchAppDialog: (state, action) => {
      state.goPrivateDontSwitchAppDialog = action.payload;
    },
    updateGoPrivateChecklistDialog: (state, action) => {
      state.goPrivateChecklistDialog = action.payload;
    },
    updateGoPrivatePermissionDialog: (state, action) => {
      state.goPrivatePermissionDialog = action.payload;
    },
    updateGoPrivatePermissionTutorialDialog: (state, action) => {
      state.goPrivatePermissionTutorialDialog = action.payload;
    },
    updateJoinPrivateAndroidWarningDialog: (state, action) => {
      state.joinPrivateAndroidWarningDialog = action.payload;
    },
    updateVideoCallCameraTesterDialog: (state, action) => {
      state.videoCallCameraTesterDialog = action.payload;
    },
    updateRestartPhoneDescriptionDialog: (state, action) => {
      state.restartPhoneDescriptionDialog = action.payload;
    },
    updatePreJoinDialog: (state, action) => {
      state.preJoinDialog = action.payload;
    },
    updatePreJoinSugarBabyWarningDialog: (state, action) => {
      state.preJoinSugarBabyWarningDialog = action.payload;
    },
    updateBusyInPrivateDialog: (state, action) => {
      state.busyInPrivateDialog = action.payload;
    },
    updateCalleeCurrentSessionSummaryDialog: (state, action) => {
      state.calleeCurrentSessionSummaryDialog = action.payload;
    },
    updateChargedMessagePopupDialog: (state, action) => {
      state.chargedMessagePopupDialog = action.payload;
    },
    updateKickUserDialog: (state, action) => {
      state.kickUserDialog = action.payload;
    },
    updateEndCallDialog: (state, action) => {
      state.endCallDialog = action.payload;
    },
    updateVideoCallInfoDialog: (state, action) => {
      state.videoCallInfoDialog = action.payload;
    },
    updatePrivateCallCallingDialog: (state, action) => {
      state.privateCallCallingDialog = action.payload;
    },
    updatePrivateCallDeclineDialog: (state, action) => {
      state.privateCallDeclineDialog = action.payload;
    },
    updatePrivateCallAcceptedDialog: (state, action) => {
      state.privateCallAcceptedDialog = action.payload;
    },
    updatePrivateCallWaitingRoomDialog: (state, action) => {
      state.privateCallWaitingRoomDialog = action.payload;
    },
    updateIncomingPrivateCallDialog: (state, action) => {
      state.incomingPrivateCallDialog = action.payload;
    },
    updateVideoCallErrorDialog: (state, action) => {
      state.videoCallErrorDialog = action.payload;
    },
    updateThankYouForReviewDialog: (state, action) => {
      state.thankYouForReviewDialog = action.payload;
    },
    updateCustomizeTipRequestDialog: (state, action) => {
      state.customizeTipRequestDialog = action.payload;
    },
    updateCustomizeTipRequestAcceptedDialog: (state, action) => {
      state.customizeTipRequestAcceptedDialog = action.payload;
    },
    updateOutOfCoinsDialog: (state, action) => {
      state.outOfCoinsDialog = action.payload;
    },

    // Go Live Functions
    updateGoLiveInfoDialog: (state, action) => {
      state.goLiveInfoDialog = action.payload;
    },

    // Tips Functions
    updateCustomizeTipsDialog: (state, action) => {
      state.customizeTipsDialog = action.payload;
    },

    // Livestream Functions
    updateLivestreamKickedDialog: (state, action) => {
      state.livestreamKickedDialog = action.payload;
    },
    updateLivestreamProfileViewDialog: (state, action) => {
      state.livestreamProfileViewDialog = action.payload;
    },
    updateLivestreamReportDialog: (state, action) => {
      state.livestreamReportDialog = action.payload;
    },
    updateLivestreamReportSuccessDialog: (state, action) => {
      state.livestreamReportSuccessDialog = action.payload;
    },
    updateDualPkEndResultDialog: (state, action) => {
      state.dualPkEndResultDialog = action.payload;
    },

    // Casino Functions
    updateCasinoGameDialog: (state, action) => {
      state.casinoGameDialog = action.payload;
    },

    // Leaderboard Functions
    updateLeaderboardEventsDialog: (state, action) => {
      state.leaderboardEventsDialog = action.payload;
    },
    updateTopLeaderboardListDialog: (state, action) => {
      state.topLeaderboardListDialog = action.payload;
    },

    // Streamer Center Functions
    updateStreamerCenterLearnMoreDialog: (state, action) => {
      state.streamerCenterLearnMoreDialog = action.payload;
    },
    updateDiamondsWithdrawalInfoDialog: (state, action) => {
      state.diamondsWithdrawalInfoDialog = action.payload;
    },

    // Product Purchase Functions
    updateCoinsPackageDialog: (state, action) => {
      state.coinsPackageDialog = action.payload;
    },
    updateProductPurchaseDialog: (state, action) => {
      state.productPurchaseDialog = action.payload;
    },
    updateLimitedTimeCoinPurchaseDialog: (state, action) => {
      state.limitedTimeCoinPurchaseDialog = action.payload;
    },
    updateThankYouForPurchaseDialog: (state, action) => {
      state.thankYouForPurchaseDialog = action.payload;
    },

    // Coins Functions
    updateBuyCoinsDialog: (state, action) => {
      state.buyCoinsDialog = action.payload;
    },
    updatePaymentProcessDialog: (state, action) => {
      state.paymentProcessDialog = action.payload;
    },
    updatePaymentErrorDialog: (state, action) => {
      state.paymentErrorDialog = action.payload;
    },
    updateAddPaymentMethodDialog: (state, action) => {
      state.addPaymentMethodDialog = action.payload;
    },
    updateAddCardDialog: (state, action) => {
      state.addCardDialog = action.payload;
    },

    // Info Functions
    updateMyWalletInfoDialog: (state, action) => {
      state.myWalletInfoDialog = action.payload;
    },
    updateDiamondsInfoDialog: (state, action) => {
      state.diamondsInfoDialog = action.payload;
    },
    updateEnableCallInfoDialog: (state, action) => {
      state.enableCallInfoDialog = action.payload;
    },
    updateRatesInfoDialog: (state, action) => {
      state.ratesInfoDialog = action.payload;
    },

    // Tier Info Functions
    updateTierInfoDialog: (state, action) => {
      state.tierInfoDialog = action.payload;
    },

    // PK Info Functions
    updatePKInfoDialog: (state, action) => {
      state.pkInfoDialog = action.payload;
    },

    // Tutorial Functions
    updateVideoCallTutorialDialog: (state, action) => {
      state.videoCallTutorialDialog = action.payload;
    },

    // Video Call Settings Tutorial Functions
    updateVideoCallSettingsTutorialDialog: (state, action) => {
      state.videoCallSettingsTutorialDialog = action.payload;
    },

    // Search Filter Functions
    updateSearchFilterDialog: (state, action) => {
      state.searchFilterDialog = action.payload;
    },
    updateBodyTypeFilterDialog: (state, action) => {
      state.bodyTypeFilterDialog = action.payload;
    },
    updateDrinkingFilterDialog: (state, action) => {
      state.drinkingFilterDialog = action.payload;
    },
    updateEducationFilterDialog: (state, action) => {
      state.educationFilterDialog = action.payload;
    },
    updateEthnicityFilterDialog: (state, action) => {
      state.ethnicityFilterDialog = action.payload;
    },
    updateEyeColorFilterDialog: (state, action) => {
      state.eyeColorFilterDialog = action.payload;
    },
    updateHairColorFilterDialog: (state, action) => {
      state.hairColorFilterDialog = action.payload;
    },
    updateLifestyleFilterDialog: (state, action) => {
      state.lifestyleFilterDialog = action.payload;
    },
    updateRelationshipStatusFilterDialog: (state, action) => {
      state.relationshipStatusFilterDialog = action.payload;
    },
    updateSmokingFilterDialog: (state, action) => {
      state.smokingFilterDialog = action.payload;
    },
    updateTorFilterDialog: (state, action) => {
      state.torFilterDialog = action.payload;
    },

    // Unfollow User Functions
    updateUnfollowUserDialog: (state, action) => {
      state.unfollowUserDialog = action.payload;
    },

    // Low Power Mode Functions
    updateLowPowerModeDialog: (state, action) => {
      state.lowPowerModeDialog = action.payload;
    },

    // Purchase Coins Functions
    updateCoinsDiscountDialog: (state, action) => {
      state.coinsDiscountDialog = action.payload;
    },

    // Camera Microphone Functions
    updateCameraMicrophoneSettingsDialog: (state, action) => {
      state.cameraMicrophoneSettingsDialog = action.payload;
    },

    // Sugarbook Verified Functions
    updateOnboardingSugarbookVerifiedDialog: (state, action) => {
      state.onboardingSugarbookVerifiedDialog = action.payload;
    },
    updateSugarbookVerifiedCameraDialog: (state, action) => {
      state.sugarbookVerifiedCameraDialog = action.payload;
    },

    // Registration Functions
    updateRegistrationAboutMeContentDialog: (state, action) => {
      state.registrationAboutMeContentDialog = action.payload;
    },
    updateUpdateRegistrationAboutMeDialog: (state, action) => {
      state.updateRegistrationAboutMeDialog = action.payload;
    },
    updateRestartRegistrationDialog: (state, action) => {
      state.restartRegistrationDialog = action.payload;
    },
    updateRegistrationProfileAlmostCompleteDialog: (state, action) => {
      state.registrationProfileAlmostCompleteDialog = action.payload;
    },

    // General Loading Functions
    updateGeneralLoadingDialog: (state, action) => {
      state.generalLoadingDialog = action.payload;
    },
    updateVerificationStatusDialog: (state, action) => {
      state.verificationStatusDialog = action.payload;
    },

    // Prevent Landscape Functions
    updatePreventLandscapeDialog: (state, action) => {
      state.preventLandscapeDialog = action.payload;
    },

    // Upgrade Promo Functions
    updateUpgradePromoDialog: (state, action) => {
      state.upgradePromoDialog = action.payload;
    },

    // Survey Monkey Functions
    updateSurveyMonkeyDialog: (state, action) => {
      state.surveyMonkeyDialog = action.payload;
    },

    // Video Splash Screen Functions
    updateVideoSplashScreenDialog: (state, action) => {
      state.videoSplashScreenDialog = action.payload;
    },

    // Redirect from old app Functions
    updateRedirectFromOldAppDialog: (state, action) => {
      state.redirectFromOldAppDialog = action.payload;
    },

    // Login Page Troubleshoot Functions
    updateLoginPageTroubleshootDialog: (state, action) => {
      state.loginPageTroubleshootDialog = action.payload;
    },
    updateConfirmAppResetDialog: (state, action) => {
      state.confirmAppResetDialog = action.payload;
    },

    // Email Registered Functions
    updateEmailRegisteredDialog: (state, action) => {
      state.emailRegisteredDialog = action.payload;
    },

    // Photo Crop Functions
    updatePhotoCropDialog: (state, action) => {
      state.photoCropDialog = action.payload;
    },

    // Testing Functions
    updatePkRoundTabDialog: (state, action) => {
      state.pkRoundTabDialog = action.payload;
    },

    // Dialog Search Param Functions
    updateDialogSearchParam: (state, action) => {
      const key = action.payload?.key;
      const value = action.payload?.value;
      const dialogSearchParamData = [...state.dialogSearchParam];

      // find if key exist in state.dialogSearchParam
      const index = dialogSearchParamData.findIndex((item) => {
        return item.key === key;
      });

      // if key exist, check if value is true or false, if true, do nothing, if false, remove the key
      if (index !== -1) {
        if (value === false) {
          dialogSearchParamData.splice(index, 1);
        }
      } else {
        // if key does not exist, check if value is true or false, if true, add the key, if false, do nothing
        if (value === true) {
          dialogSearchParamData.push(key);
        } else {
          dialogSearchParamData.splice(index, 1);
        }
      }

      state.dialogSearchParam = dialogSearchParamData;
    },

    // Utility Functions
    resetDialogStore: () => {
      return { ...initialState };
    },
  },
});

export const {
  // Choose Your Role Functions
  updateChooseYourRoleDialog,
  
  // Whats A Role Functions
  updateWhatsARoleDialog,

  // Add to homescreen Functions
  updateSaveToHomescreenDialog,
  updateSaveToHomescreenTutorialDialog,

  // Geolocation Permission Functions
  updateGeolocationPermissionPromptDialog,
  updateGeolocationPermissionDeniedDialog,

  // Notification Permission Functions
  updateNotificationPermissionPromptDialog,

  // Live Introduction Functions
  updateLiveIntroductionDialog,

  // Whats New Functions
  updateWhatsNewDialog,

  // VPN Functions
  updateVpnBlockDialog,

  // Daily Check In Functions
  updateDailyCheckInDialog,

  // Levels Functions
  updateLevelUpDialog,
  updateLevelsDescriptionDialog,

  // Check in Functions
  updateCheckInStreakDialog,

  // Onboarding diamonds info Functions
  updateOnboardingDiamondsInfoDialog,

  // Onboarding video call Functions
  updateOnboardingVideoCallDialog,

  // Onboarding video call room Functions
  updateOnboardingVideoCallRoomDialog,

  // Onboarding private room Functions
  updateOnboardingPrivateRoomDaddyDialog,
  updateOnboardingPrivateRoomBabyDialog,

  // Onboarding live streaming Functions
  updateOnboardingLiveStreamingDialog,

  // Upgrade to Premium Functions
  updateUpgradeToPremiumDialog,

  // Full Sugar Experience Functions
  updateFullSugarExperienceDialog,

  // Missing TOR Functions
  updateMissingTorDialog,
  updateCompleteTorDialog,

  // Update TOR Functions
  updateUpdateTorDialog,

  // Edit Profile Dialog
  updateUpdateProfileFullscreenDialog,
  updateEditProfileUnsavedChangesDialog,

  // Deactivate Functions
  updateDeactivateAccountDialog,

  // User Profile Functions
  updateUserProfileDialog,
  updateUserIsLiveDialog,

  // Profile Achievement Badge Functions
  updateProfileAchievementBadgeDialog,

  // Verified Caller Description Functions
  updateVerifiedCallerDescriptionDialog,

  // Preview Photo Functions
  updatePreviewPhotoDialog,

  // Profile Rejected Info Functions
  updateProfileRejectedInfoDialog,

  // View TOR Description Functions
  updateViewTorDescriptionDialog,

  // Request Private Photo Functions
  updateRequestPrivatePhotoDialog,

  // Report Functions
  updateReportDialog,

  // Clear Messages Functions
  updateClearMessagesDialog,

  // Video Call Functions
  updateGoPrivateAddToHomeScreenDialog,
  updateGoPrivateDontSwitchAppDialog,
  updateGoPrivateChecklistDialog,
  updateGoPrivatePermissionDialog,
  updateGoPrivatePermissionTutorialDialog,
  updateJoinPrivateAndroidWarningDialog,
  updateVideoCallCameraTesterDialog,
  updateRestartPhoneDescriptionDialog,
  updatePreJoinDialog,
  updatePreJoinSugarBabyWarningDialog,
  updateBusyInPrivateDialog,
  updateCalleeCurrentSessionSummaryDialog,
  updateChargedMessagePopupDialog,
  updateKickUserDialog,
  updateEndCallDialog,
  updateVideoCallInfoDialog,
  updatePrivateCallCallingDialog,
  updatePrivateCallDeclineDialog,
  updatePrivateCallAcceptedDialog,
  updatePrivateCallWaitingRoomDialog,
  updateIncomingPrivateCallDialog,
  updateVideoCallErrorDialog,
  updateThankYouForReviewDialog,
  updateCustomizeTipRequestDialog,
  updateCustomizeTipRequestAcceptedDialog,
  updateOutOfCoinsDialog,

  // Go Live Functions
  updateGoLiveInfoDialog,

  // Tips Functions
  updateCustomizeTipsDialog,

  // Livestream Functions
  updateLivestreamKickedDialog,
  updateLivestreamProfileViewDialog,
  updateLivestreamReportDialog,
  updateLivestreamReportSuccessDialog,
  updateDualPkEndResultDialog,

  // Casino Functions
  updateCasinoGameDialog,

  // Leaderboard Functions
  updateLeaderboardEventsDialog,
  updateTopLeaderboardListDialog,

  // Streamer Center Functions
  updateStreamerCenterLearnMoreDialog,
  updateDiamondsWithdrawalInfoDialog,

  // Product Purchase Functions
  updateCoinsPackageDialog,
  updateProductPurchaseDialog,
  updateLimitedTimeCoinPurchaseDialog,
  updateThankYouForPurchaseDialog,

  // Coins Functions
  updateBuyCoinsDialog,
  updatePaymentProcessDialog,
  updatePaymentErrorDialog,
  updateAddPaymentMethodDialog,
  updateAddCardDialog,

  // Info Functions
  updateMyWalletInfoDialog,
  updateDiamondsInfoDialog,
  updateEnableCallInfoDialog,
  updateRatesInfoDialog,

  // Tier Info Functions
  updateTierInfoDialog,

  // PK Info Functions
  updatePKInfoDialog,

  // Tutorial Functions
  updateVideoCallTutorialDialog,

  // Video Call Settings Tutorial Functions
  updateVideoCallSettingsTutorialDialog,

  // Search Filter Functions
  updateSearchFilterDialog,
  updateBodyTypeFilterDialog,
  updateDrinkingFilterDialog,
  updateEducationFilterDialog,
  updateEthnicityFilterDialog,
  updateEyeColorFilterDialog,
  updateHairColorFilterDialog,
  updateLifestyleFilterDialog,
  updateRelationshipStatusFilterDialog,
  updateSmokingFilterDialog,
  updateTorFilterDialog,

  // Unfollow User Functions
  updateUnfollowUserDialog,

  // Low Power Mode Functions
  updateLowPowerModeDialog,

  // Purchase Coins Functions
  updateCoinsDiscountDialog,

  // Camera Microphone Functions
  updateCameraMicrophoneSettingsDialog,

  // Sugarbook Verified Functions
  updateOnboardingSugarbookVerifiedDialog,
  updateSugarbookVerifiedCameraDialog,

  // Registration Functions
  updateRegistrationAboutMeContentDialog,
  updateUpdateRegistrationAboutMeDialog,
  updateRestartRegistrationDialog,
  updateRegistrationProfileAlmostCompleteDialog,

  // General Loading Functions
  updateGeneralLoadingDialog,
  updateVerificationStatusDialog,

  // Prevent Landscape Functions
  updatePreventLandscapeDialog,

  // Upgrade Promo Functions
  updateUpgradePromoDialog,

  // Survey Monkey Functions
  updateSurveyMonkeyDialog,

  // Video Splash Screen Functions
  updateVideoSplashScreenDialog,

  // Redirect from old app Functions
  updateRedirectFromOldAppDialog,

  // Login Page Troubleshoot Functions
  updateLoginPageTroubleshootDialog,
  updateConfirmAppResetDialog,

  // Email Registered Functions
  updateEmailRegisteredDialog,

  // Photo Crop Functions
  updatePhotoCropDialog,

  // Testing Functions
  updatePkRoundTabDialog,

  // Dialog Search Param Functions
  updateDialogSearchParam,

  // Utility Functions
  resetDialogStore,
} = dialogSlice.actions;

export default dialogSlice.reducer;
