// General
import { useEffect } from "react";
// Services
import {
  useLazyGetJourneyQuery,
  useCompleteJourneyMutation,
  useLazyGetProfileQuery,
  useInitiateEmailVerificationMutation,
} from "../../../../services/data.service";
import { sessionService } from "../../../../services/session.service";
// Static Data
import user from "../../../../enums/user";
import userConst from "../../../../const/userConst";
import registrationConst from "../../../../const/registrationConst";
import routeConst from "../../../../const/routeConst";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateRegistrationNavigationData,
  updateInitiateEmailApiLoading,
  updateJourneyDataPassthrough,
  updateJourneyNavigationPassthrough,
  updateProfileDataPassthrough,
  updateAboutMeManualText,
  updateProfilePhoto,
  updateProfilePhotoDataPassthrough,
  updateCompleteJourneyPassthrough,
  updateIsProfileMeLoading,
  updateIsCompleteJourneyLoading,
  updateIsInJourney,
  updateCroppedImage,
} from "../../../../redux/store/registrationStore";
import {
  // Email Verification Functions
  setEmailVerificationTimer,
  updateEmailVerificationDisplay,
  updateEmailVerificationRequired,

  // Mobile Verification Functions
  updateMobileVerificationDisplay,
  updateMobileVerificationRequired,
} from "../../../../redux/store/verificationStore";
import {
  updateProfileCompletionPercentage,
  updateProfileRequirementPopup,
} from "../../../../redux/store/profileStore";
import { resetRestartJourneyCounter } from "../../../../redux/store/searchStore";
import { updateIsLoggedIn } from "../../../../redux/store/publicStore";
import { updateErrorToast } from "../../../../redux/store/toastStore";
import { updateRegistrationProfileAlmostCompleteDialog } from "../../../../redux/store/dialogStore";
// Moment
import moment from "moment";
// i18next
import { useTranslation } from "react-i18next";
// Components
import useCustomNavigate from "../../custom-hooks/useCustomNavigate-hook";

const JourneyNavigationHelper = () => {
  // API variables
  const [
    getJourney,
    {
      data: getJourneyData,
      error: getJourneyErrorData,
      isFetching: getJourneyFetching,
      isLoading: getJourneyLoading,
      isSuccess: getJourneySuccess,
      isError: getJourneyError,
    },
  ] = useLazyGetJourneyQuery();
  const [
    completeJourney,
    {
      data: completeJourneyData,
      error: completeJourneyErrorData,
      isLoading: completeJourneyLoading,
      isSuccess: completeJourneySuccess,
      isError: completeJourneyError,
    },
  ] = useCompleteJourneyMutation();
  const [
    getProfile,
    {
      data: getProfileData,
      error: getProfileErrorData,
      isFetching: getProfileFetching,
      isLoading: getProfileLoading,
      isSuccess: getProfileSuccess,
      isError: getProfileError,
    },
  ] = useLazyGetProfileQuery();
  const [
    initiateEmailVerification,
    {
      data: initiateEmailVerificationData,
      error: initiateEmailVerificationErrorData,
      isLoading: initiateEmailVerificationLoading,
      isSuccess: initiateEmailVerificationSuccess,
      isError: initiateEmailVerificationError,
    },
  ] = useInitiateEmailVerificationMutation();

  // Redux variables
  const registrationNavigationData = useSelector(
    (state) => state.registration.registrationNavigationData
  );
  const journeyNavigationPassthrough = useSelector(
    (state) => state.registration.journeyNavigationPassthrough
  );
  const journeyDataPassthrough = useSelector(
    (state) => state.registration.journeyDataPassthrough
  );
  const profileDataPassthrough = useSelector(
    (state) => state.registration.profileDataPassthrough
  );
  const profilePhotoDataPassthrough = useSelector(
    (state) => state.registration.profilePhotoDataPassthrough
  );
  const verificationDataPassthrough = useSelector(
    (state) => state.registration.verificationDataPassthrough
  );
  const verificationNavigatePassthrough = useSelector(
    (state) => state.registration.verificationNavigatePassthrough
  );
  const premiumBenefitsPassthrough = useSelector(
    (state) => state.registration.premiumBenefitsPassthrough
  );
  const completeJourneyPassthrough = useSelector(
    (state) => state.registration.completeJourneyPassthrough
  );
  const lastLocation = useSelector((state) => state.registration.lastLocation);
  const utmSource = useSelector((state) => state.tracking.utmSource);
  const dispatch = useDispatch();

  // i18next variables
  const { i18n } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Lifecycle | Check for update | Journey API Response
  useEffect(() => {
    if (getJourneyFetching || getJourneyLoading) {
    } else if (getJourneySuccess) {
      dispatch(updateJourneyDataPassthrough(getJourneyData));
      getNextJourney();
    } else if (getJourneyError) {
    }
  }, [
    getJourneyFetching,
    getJourneyLoading,
    getJourneySuccess,
    getJourneyError,
  ]);

  // Lifecycle | Check for update | Complete Journey API Response
  useEffect(() => {
    if (completeJourneyLoading) {
      dispatch(updateIsCompleteJourneyLoading(true));
    } else if (completeJourneySuccess) {
      dispatch(updateIsCompleteJourneyLoading(false));
      dispatch(updateJourneyDataPassthrough(completeJourneyData));
      dispatch(updateIsInJourney(false));

      if (completeJourneyData?.user_journey?.restarted === true) {
        if (checkCompleteJourney()) {
          dispatch(updateRegistrationProfileAlmostCompleteDialog(true));
        }
      } else {
        if (sessionService?.getRestartJourneyDialogLastShown()) {
          sessionService.deleteRestartJourneyDialogLastShown();
        }

        dispatch(resetRestartJourneyCounter());
      }

      setTimeout(() => {
        dispatch(updateCompleteJourneyPassthrough({}));
        dispatch(updateCroppedImage(null));
      }, 1000);
    } else if (completeJourneyError) {
      dispatch(updateIsCompleteJourneyLoading(false));

      if (completeJourneyErrorData?.message) {
        const errorToast = {
          message: completeJourneyErrorData?.message,
          autoClose: 3000,
        };
        dispatch(updateErrorToast(errorToast));
      }
    }
  }, [completeJourneyLoading, completeJourneySuccess, completeJourneyError]);

  // Lifecycle | Check for update | Profile API Response
  useEffect(() => {
    if (getProfileFetching || getProfileLoading) {
      dispatch(updateIsProfileMeLoading(true));
    } else if (getProfileSuccess) {
      dispatch(updateIsProfileMeLoading(false));
      switch (getProfileData?.status) {
        case 0:
          dispatch(
            updateProfileCompletionPercentage(
              getProfileData?.data?.profile_progress?.summary?.progress
            )
          );
          dispatch(
            updateProfileRequirementPopup(
              getProfileData?.data?.profile_progress?.summary?.requirement
            )
          );
          dispatch(updateProfileDataPassthrough(getProfileData));
          dispatch(updateProfilePhotoDataPassthrough(getProfileData));
          dispatch(
            updateProfilePhoto(
              getProfileData?.data?.profile_photo?.original_photo
            )
          );
          dispatch(updateAboutMeManualText(getProfileData?.data?.aboutme));
          getNextJourney();
          break;
        default:
          break;
      }
    } else if (getProfileError) {
      dispatch(updateIsProfileMeLoading(false));
    }
  }, [
    getProfileFetching,
    getProfileLoading,
    getProfileSuccess,
    getProfileError,
  ]);

  // Lifecycle | Check for update | Initiate Email Verification API Response
  useEffect(() => {
    if (initiateEmailVerificationLoading) {
      dispatch(updateInitiateEmailApiLoading(true));
    } else if (initiateEmailVerificationSuccess) {
      dispatch(updateInitiateEmailApiLoading(false));

      switch (initiateEmailVerificationData?.status) {
        case 1:
          const createdAt =
            initiateEmailVerificationData?.data?.email_verification?.created_at;
          const expiredAt =
            initiateEmailVerificationData?.data?.email_verification?.expired_at;
          const diffInSeconds = moment(expiredAt).diff(createdAt, "seconds");

          dispatch(setEmailVerificationTimer(60));
          onNavigate(routeConst.verify.emailVerification.path);
          break;
        case -1:
          // Need to wait 3 minutes
          const errorToast = {
            message: initiateEmailVerificationData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(errorToast));
          break;
        default:
          // Not sure
          break;
      }
    } else if (initiateEmailVerificationError) {
      dispatch(updateInitiateEmailApiLoading(false));
    }
  }, [
    initiateEmailVerificationLoading,
    initiateEmailVerificationSuccess,
    initiateEmailVerificationError,
  ]);

  // Lifecycle | Check for update | verificationDataPassthrough
  // useEffect(() => {

  // }, [verificationDataPassthrough]);

  // Lifecycle | Check for update | verificationNavigatePassthrough
  useEffect(() => {
    if (!verificationNavigatePassthrough) return;

    getNextVerification();
  }, [verificationNavigatePassthrough]);

  // Lifecycle | Check for update | journeyNavigationPassthrough
  useEffect(() => {
    if (!journeyNavigationPassthrough) return;

    getNextJourney();
  }, [journeyNavigationPassthrough]);

  // Lifecycle | Check for update | completeJourneyPassthrough
  useEffect(() => {
    if (!completeJourneyPassthrough) return;

    getNextJourney();
  }, [completeJourneyPassthrough]);

  // Utility Functions
  const getNextVerification = () => {
    if (
      !verificationDataPassthrough?.email?.verified &&
      verificationDataPassthrough?.email?.verification_display
    ) {
      // Email Verification
      initiateEmailVerification("useJourneyNavigation", false);
    } else if (
      !verificationDataPassthrough?.mobile?.verified &&
      verificationDataPassthrough?.mobile?.verification_display
    ) {
      // Mobile Verification
      onNavigate(routeConst.phoneAuthentication.altPath);
    } else {
      getJourney("useJourneyNavigation", false);
    }
  };
  const getNextJourney = () => {
    if (!journeyDataPassthrough) {
      getJourney("useJourneyNavigation", false);
    } else if (!journeyDataPassthrough?.user_journey?.completed) {
      if (journeyDataPassthrough?.user_journey?.restarted === true) {
        // journeyNavigateMissingField();
        journeyNavigateMissingFieldSkippable();
      } else {
        journeyNavigateStep();
      }
    } else if (journeyDataPassthrough?.user_journey?.restarted === false) {
      if (
        premiumBenefitsPassthrough === registrationConst.premiumBenefits.skip
      ) {
        redirectOut();
      } else if (
        premiumBenefitsPassthrough === registrationConst.premiumBenefits.upgrade
      ) {
        // Premium Benefits
        onNavigate(routeConst.premiumBenefits.upgrade.path);
      } else if (
        premiumBenefitsPassthrough ===
        registrationConst.premiumBenefits.unassigned
      ) {
        // Premium Benefits
        onNavigate(routeConst.premiumBenefits.path);
      }
    } else {
      redirectOut();
    }
  };
  const journeyNavigateMissingField = () => {
    if (
      !journeyDataPassthrough?.user_journey?.journey_data?.username ||
      !journeyDataPassthrough?.user_journey?.journey_data?.birth_date
    ) {
      // Username & Birthdate
      onNavigate(routeConst.usernameSignup.path, false);
    } else if (!profileDataPassthrough) {
      getProfile("useJourneyNavigation", false);
    } else if (
      !profileDataPassthrough?.data?.tor_tags &&
      registrationNavigationData[0].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // TOR
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 1 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=0");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile
        ?.annual_income &&
      registrationNavigationData[1].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Annual Income
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 1 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=1");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.networth &&
      registrationNavigationData[2].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Networth
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 2 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=2");
    } else if (
      (journeyDataPassthrough?.user_journey?.journey_data?.wanttobe ===
      userConst.wantToBe.sugardaddy.value
        ? !journeyDataPassthrough?.user_journey?.journey_data?.profile
            ?.dating_budget
        : !journeyDataPassthrough?.user_journey?.journey_data?.profile
            ?.lifestyle) &&
      registrationNavigationData[3].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Lifestyle
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 3 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=3");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile
        ?.relationship &&
      registrationNavigationData[4].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Relationship Status
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 4 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=4");
    } else if (
      (!journeyDataPassthrough?.user_journey?.journey_data?.profile
        ?.dating_style ||
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.dating_style?.length <= 0) &&
      registrationNavigationData[5].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Dating Style
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 5 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=5");
    } else if (
      journeyDataPassthrough?.user_journey?.journey_data?.wanttobe ===
        userConst.wantToBe.sugardaddy.value &&
      (!journeyDataPassthrough?.user_journey?.journey_data?.profile?.position ||
        !journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.industry) &&
      registrationNavigationData[6].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Occupation // Position // Industry
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 6 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=6");
    } else if (
      journeyDataPassthrough?.user_journey?.journey_data?.wanttobe ===
        userConst.wantToBe.sugarbaby.value &&
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.position &&
      registrationNavigationData[6].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Occupation // Position // Industry
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 6 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=6");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.ethnicity &&
      registrationNavigationData[7].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Ethnicity
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 7 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=7");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.race &&
      registrationNavigationData[8].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Race
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 8 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=8");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.smoking &&
      registrationNavigationData[9].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Smoking
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 9 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=9");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.drinking &&
      registrationNavigationData[10].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Drinking
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 10 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=10");
    } else if (
      (!journeyDataPassthrough?.user_journey?.journey_data?.profile?.alcohol ||
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.alcohol
          ?.length <= 0) &&
      journeyDataPassthrough?.user_journey?.journey_data?.profile?.drinking !==
        540 &&
      registrationNavigationData[11].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Alcohol Preference
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 11 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=11");
    } else if (
      checkAboutMeSection() < 3 &&
      registrationNavigationData[12].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // About Me
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 12 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=12");
    } else if (
      (!journeyDataPassthrough?.user_journey?.journey_data?.looking_for
        ?.age_max ||
        !journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.age_min ||
        !journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.ethnicities ||
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.ethnicities?.length <= 0 ||
        !journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.locations ||
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.locations?.length <= 0) &&
      registrationNavigationData[13].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Looking For
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 13 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=13");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.livestream
        ?.join_livestream &&
      !journeyDataPassthrough?.user_journey?.journey_data?.livestream
        ?.date_streamer &&
      registrationNavigationData[14].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Livestreaming
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 14 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=14");
    }
    // else if (
    //   !profilePhotoDataPassthrough?.data?.public_photos[0]?.original_photo &&
    //   registrationNavigationData[15].rolePermission.includes(
    //     journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
    //   )
    // ) {
    //   // Photos
    //   const updatedItems = registrationNavigationData?.map((item, i) => {
    //     return i === 15 ? { ...item, isClickable: true } : item;
    //   });
    //   dispatch(updateRegistrationNavigationData(updatedItems));
    //   onNavigate(routeConst.detailsSignup.path, false, "?active=15");
    // }
    else {
      completeJourney();
    }
  };
  const journeyNavigateStep = () => {
    if (
      !journeyDataPassthrough?.user_journey?.journey_data?.username ||
      !journeyDataPassthrough?.user_journey?.journey_data?.birth_date
    ) {
      // Username & Birthdate
      onNavigate(routeConst.usernameSignup.path, false);
    } else if (!profileDataPassthrough) {
      getProfile("useJourneyNavigation", false);
    } else if (
      parseInt(journeyDataPassthrough?.user_journey?.journey_step) === 14 &&
      i18n.language.toLowerCase().includes("zh-tw")
    ) {
      // Livestream | Dont show for TW users
    } else if (
      parseInt(journeyDataPassthrough?.user_journey?.journey_step) < 15
    ) {
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i ===
          parseInt(journeyDataPassthrough?.user_journey?.journey_step)
          ? { ...item, isClickable: true }
          : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(
        routeConst.detailsSignup.path,
        false,
        `?active=${journeyDataPassthrough?.user_journey?.journey_step?.toString()}`
      );
    } else if (
      journeyDataPassthrough?.user_journey?.journey_step === "15" &&
      registrationNavigationData[15].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Photos
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 15 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=15");
    }
    // else if (
    //   journeyDataPassthrough?.user_journey?.journey_step === "16" &&
    //   premiumBenefitsPassthrough ===
    //     registrationConst.premiumBenefits.unassigned
    // ) {
    //   // Premium Benefits
    //   onNavigate(routeConst.premiumBenefits.path);
    // } else if (
    //   premiumBenefitsPassthrough === registrationConst.premiumBenefits.upgrade
    // ) {
    //   // Upgrade
    //   onNavigate(routeConst.premiumBenefits.upgrade.path);
    // }
    else {
      completeJourney();
    }
  };
  const journeyNavigateMissingFieldSkippable = () => {
    // if (
    //   !journeyDataPassthrough?.user_journey?.journey_data?.username ||
    //   !journeyDataPassthrough?.user_journey?.journey_data?.birth_date
    // ) {
    //   // Username & Birthdate
    //   onNavigate(routeConst.usernameSignup.path, false);
    // } else
    if (!profileDataPassthrough) {
      getProfile("useJourneyNavigation", false);
    } else if (
      !profileDataPassthrough?.data?.tor_tags &&
      registrationNavigationData[0].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // TOR
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 1 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=0");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile
        ?.annual_income &&
      !registrationNavigationData[1].skipped &&
      registrationNavigationData[1].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Annual Income
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 1 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=1");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.networth &&
      !registrationNavigationData[2].skipped &&
      registrationNavigationData[2].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Networth
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 2 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=2");
    } else if (
      (journeyDataPassthrough?.user_journey?.journey_data?.wanttobe ===
      userConst.wantToBe.sugardaddy.value
        ? !journeyDataPassthrough?.user_journey?.journey_data?.profile
            ?.dating_budget
        : !journeyDataPassthrough?.user_journey?.journey_data?.profile
            ?.lifestyle) &&
      !registrationNavigationData[3].skipped &&
      registrationNavigationData[3].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Lifestyle
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 3 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=3");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile
        ?.relationship &&
      !registrationNavigationData[4].skipped &&
      registrationNavigationData[4].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Relationship Status
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 4 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=4");
    } else if (
      (!journeyDataPassthrough?.user_journey?.journey_data?.profile
        ?.dating_style ||
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.dating_style?.length <= 0) &&
      !registrationNavigationData[5].skipped &&
      registrationNavigationData[5].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Dating Style
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 5 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=5");
    } else if (
      journeyDataPassthrough?.user_journey?.journey_data?.wanttobe ===
        userConst.wantToBe.sugardaddy.value &&
      (!journeyDataPassthrough?.user_journey?.journey_data?.profile?.position ||
        !journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.industry) &&
      !registrationNavigationData[6].skipped &&
      registrationNavigationData[6].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Occupation // Position // Industry
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 6 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=6");
    } else if (
      journeyDataPassthrough?.user_journey?.journey_data?.wanttobe ===
        userConst.wantToBe.sugarbaby.value &&
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.position &&
      !registrationNavigationData[6].skipped &&
      registrationNavigationData[6].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Occupation // Position // Industry
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 6 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=6");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.ethnicity &&
      !registrationNavigationData[7].skipped &&
      registrationNavigationData[7].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Ethnicity
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 7 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=7");
    } else if (
      journeyDataPassthrough?.user_journey?.journey_data?.profile?.ethnicity &&
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.race &&
      !registrationNavigationData[8].skipped &&
      registrationNavigationData[8].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Race
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 8 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=8");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.smoking &&
      !registrationNavigationData[9].skipped &&
      registrationNavigationData[9].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Smoking
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 9 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=9");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.profile?.drinking &&
      !registrationNavigationData[10].skipped &&
      registrationNavigationData[10].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Drinking
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 10 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=10");
    } else if (
      (!journeyDataPassthrough?.user_journey?.journey_data?.profile?.alcohol ||
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.alcohol
          ?.length <= 0) &&
      journeyDataPassthrough?.user_journey?.journey_data?.profile?.drinking &&
      journeyDataPassthrough?.user_journey?.journey_data?.profile?.drinking !==
        540 &&
      !registrationNavigationData[11].skipped &&
      registrationNavigationData[11].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Alcohol Preference
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 11 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=11");
    } else if (
      checkAboutMeSection() < 3 &&
      !registrationNavigationData[12].skipped &&
      registrationNavigationData[12].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // About Me
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 12 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=12");
    } else if (
      (!journeyDataPassthrough?.user_journey?.journey_data?.looking_for
        ?.age_max ||
        !journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.age_min ||
        !journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.ethnicities ||
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.ethnicities?.length <= 0) &&
      // || !journeyDataPassthrough?.user_journey?.journey_data?.looking_for
      //   ?.locations ||
      // journeyDataPassthrough?.user_journey?.journey_data?.looking_for
      //   ?.locations?.length <= 0
      !registrationNavigationData[13].skipped &&
      registrationNavigationData[13].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      )
    ) {
      // Looking For
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 13 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=13");
    } else if (
      !journeyDataPassthrough?.user_journey?.journey_data?.livestream
        ?.join_livestream &&
      !journeyDataPassthrough?.user_journey?.journey_data?.livestream
        ?.date_streamer &&
      !registrationNavigationData[14].skipped &&
      registrationNavigationData[14].rolePermission.includes(
        journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
      ) &&
      !i18n.language.toLowerCase().includes("zh-tw")
    ) {
      // Livestreaming
      const updatedItems = registrationNavigationData?.map((item, i) => {
        return i === 14 ? { ...item, isClickable: true } : item;
      });
      dispatch(updateRegistrationNavigationData(updatedItems));
      onNavigate(routeConst.detailsSignup.path, false, "?active=14");
    }
    // else if (
    //   !profilePhotoDataPassthrough?.data?.public_photos[0]?.original_photo &&
    //   registrationNavigationData[15].rolePermission.includes(
    //     journeyDataPassthrough?.user_journey?.journey_data?.wanttobe
    //   )
    // ) {
    //   // Photos
    //   const updatedItems = registrationNavigationData?.map((item, i) => {
    //     return i === 15 ? { ...item, isClickable: true } : item;
    //   });
    //   dispatch(updateRegistrationNavigationData(updatedItems));
    //   onNavigate(routeConst.detailsSignup.path, false, "?active=15");
    // }
    else {
      completeJourney();
    }
  };
  const redirectOut = () => {
    // Check utm, etc
    dispatch(updateIsLoggedIn(true));

    if (utmSource?.utm_navigate) {
      onNavigate(utmSource?.utm_navigate, true);
    } else if (lastLocation) {
      if (
        lastLocation === routeConst.usernameSignup.path ||
        lastLocation === routeConst.usernameSignupComplete.path ||
        lastLocation === routeConst.detailsSignup.path ||
        lastLocation === routeConst.premiumBenefits.path ||
        lastLocation === routeConst.premiumBenefits.upgrade.path
      ) {
        onNavigate(routeConst.search.path, true);
      } else {
        onNavigate(lastLocation, true);
      }
    } else {
      onNavigate(routeConst.search.path, true);
    }
  };
  const checkAboutMeSection = () => {
    let aboutMeStateCounter = 0;

    // Check if user has completed about me section
    if (
      journeyDataPassthrough?.user_journey?.journey_data?.profile
        ?.personality_traits
    ) {
      aboutMeStateCounter++;
    }
    if (
      journeyDataPassthrough?.user_journey?.journey_data?.profile
        ?.personality_type
    ) {
      aboutMeStateCounter++;
    }
    // if (
    //   journeyDataPassthrough?.user_journey?.journey_data?.profile?.dating_style
    // ) {
    //   aboutMeStateCounter++;
    // }
    if (
      journeyDataPassthrough?.user_journey?.journey_data?.profile?.star_sign
    ) {
      aboutMeStateCounter++;
    }
    if (
      journeyDataPassthrough?.user_journey?.journey_data?.profile
        ?.dominant_or_submissive
    ) {
      aboutMeStateCounter++;
    }
    if (
      journeyDataPassthrough?.user_journey?.journey_data?.profile?.fitness_level
    ) {
      aboutMeStateCounter++;
    }
    if (journeyDataPassthrough?.user_journey?.journey_data?.profile?.hobbies) {
      aboutMeStateCounter++;
    }

    return aboutMeStateCounter;
  };
  const checkCompleteJourney = () => {
    if (
      journeyDataPassthrough?.user_journey?.journey_data?.wanttobe ===
      userConst.wantToBe.sugardaddy.value
    ) {
      if (
        journeyDataPassthrough?.user_journey?.journey_data?.username &&
        journeyDataPassthrough?.user_journey?.journey_data?.birth_date &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.annual_income &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.networth &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.dating_budget &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.relationship &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.dating_style &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.dating_style?.length > 0 &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.position &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.industry &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.ethnicity &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.race &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.smoking &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.drinking &&
        (journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.drinking !== 540
          ? journeyDataPassthrough?.user_journey?.journey_data?.profile
              ?.alcohol &&
            journeyDataPassthrough?.user_journey?.journey_data?.profile?.alcohol
              ?.length > 0
          : true) &&
        checkAboutMeSection() >= 3 &&
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.age_max &&
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.age_min &&
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.ethnicities &&
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.ethnicities?.length > 0 &&
        // journeyDataPassthrough?.user_journey?.journey_data?.looking_for
        //   ?.locations &&
        // journeyDataPassthrough?.user_journey?.journey_data?.looking_for
        //   ?.locations?.length > 0 &&
        (i18n.language.toLowerCase().includes("zh-tw")
          ? true
          : journeyDataPassthrough?.user_journey?.journey_data?.livestream
              ?.date_streamer)
      ) {
        return true;
      }
    } else if (
      journeyDataPassthrough?.user_journey?.journey_data?.wanttobe ===
      userConst.wantToBe.sugarbaby.value
    ) {
      if (
        journeyDataPassthrough?.user_journey?.journey_data?.username &&
        journeyDataPassthrough?.user_journey?.journey_data?.birth_date &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.lifestyle &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.relationship &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.dating_style &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.position &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.ethnicity &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.race &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.smoking &&
        journeyDataPassthrough?.user_journey?.journey_data?.profile?.drinking &&
        (journeyDataPassthrough?.user_journey?.journey_data?.profile
          ?.drinking !== 540
          ? journeyDataPassthrough?.user_journey?.journey_data?.profile
              ?.alcohol &&
            journeyDataPassthrough?.user_journey?.journey_data?.profile?.alcohol
              ?.length > 0
          : true) &&
        checkAboutMeSection() >= 3 &&
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.age_max &&
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.age_min &&
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.ethnicities &&
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          ?.ethnicities?.length > 0 &&
        journeyDataPassthrough?.user_journey?.journey_data?.looking_for(
          //   ?.locations &&
          // journeyDataPassthrough?.user_journey?.journey_data?.looking_for
          //   ?.locations?.length > 0 &&
          i18n.language.toLowerCase().includes("zh-tw")
            ? true
            : journeyDataPassthrough?.user_journey?.journey_data?.livestream
                ?.join_livestream
        )
      ) {
        return true;
      }
    }

    return false;
  };

  return <div id="journey-navigate-helper-subcomponent"></div>;
};

export default JourneyNavigationHelper;
