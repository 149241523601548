// General
import "./banner-tester.scss";
import { useState } from "react";
// Material UI
import { Button, Divider } from "@mui/material";
// Components
import Banner from "../../shared/elements/banner/banner";
import SearchPageBannerTester from "./search-page-banner-tester/search-page-banner-tester";
import InboxPageBannerTester from "./inbox-page-banner-tester/inbox-page-banner-tester";
import LivePageBannerTester from "./live-page-banner-tester/live-page-banner-tester";
import ProfileOverviewPageBannerTester from "./profile-overview-page-banner-tester/profile-overview-page-banner-tester";

const BannerTester = () => {
  // General variables
  const [searchImageData, setSearchImageData] = useState(null);
  const [inboxImageData, setInboxImageData] = useState(null);
  const [liveImageData, setLiveImageData] = useState(null);
  const [interestImageData, setInterestImageData] = useState(null);
  const [profileOverviewImageData, setProfileOverviewImageData] =
    useState(null);
  const [leaderboardImageData, setLeaderboardImageData] = useState(null);

  // Event Handlers | Button
  const onFilePicker = async (event, page) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const imageData = await readFile(file);

        switch (page) {
          case "search":
            setSearchImageData((prevState) => ({
              ...prevState,
              image: imageData,
              file: file,
            }));
            break;
          case "inbox":
            setInboxImageData((prevState) => ({
              ...prevState,
              image: imageData,
              file: file,
            }));
            break;
          case "live":
            setLiveImageData((prevState) => ({
              ...prevState,
              image: imageData,
              file: file,
            }));
            break;
          case "interest":
            setInterestImageData((prevState) => ({
              ...prevState,
              image: imageData,
              file: file,
            }));
            break;
          case "profileOverview":
            setProfileOverviewImageData((prevState) => ({
              ...prevState,
              image: imageData,
              file: file,
            }));
            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div id="banner-tester-page">
      <div className="banner-tester-label">Banner Tester</div>

      <SearchPageBannerTester />

      <Divider />

      <InboxPageBannerTester />

      <Divider />

      <LivePageBannerTester />

      <Divider />

      <ProfileOverviewPageBannerTester />

      <Divider />

      <div className="leaderboard-page-banner-container">
        <div className="page-label">Leaderboard Page</div>

        <div className="profile-overview-page-banner">
          <Banner type={"leaderboard-livestreaming-normal"} view={"stack"} />
          <Banner type={"leaderboard-private-call-normal"} view={"stack"} />
          <Banner type={"leaderboard-casino-normal"} view={"stack"} />
          <Banner type={"leaderboard-livestreaming-pk"} view={"stack"} />
        </div>
      </div>
    </div>
  );
};

export default BannerTester;
