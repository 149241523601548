// General
import "./level-up-dialog.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { resetLevelUpInfo } from "../../../../redux/store/userStore";
import { updateLevelUpDialog } from "../../../../redux/store/dialogStore";
// dotlottie
import "@dotlottie/player-component";
// Material UI
import { Dialog } from "@mui/material";
// i18next
import { useTranslation } from "react-i18next";

const LevelUpDialog = () => {
  // Redux variables
  const levelUpDialog = useSelector((state) => state.dialog.levelUpDialog);
  const levelUpInfo = useSelector((state) => state.user.levelUpInfo);
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(resetLevelUpInfo());
    dispatch(updateLevelUpDialog(false));
  };

  return (
    <Dialog
      className="custom-radius25-dialog"
      open={levelUpDialog}
      onClose={onCloseDialog}
    >
      <div id="level-up-dialog">
        {levelUpInfo?.confetti && (
          <div className="lottie-player-container">
            <dotlottie-player
              autoplay
              loop={false}
              playMode={"normal"}
              src={levelUpInfo?.confetti}
            />
          </div>
        )}

        <div className="padding-container">
          <div
            className="level-up-title"
            style={{ color: levelUpInfo?.font_color }}
          >
            {levelUpInfo?.locale_title}
          </div>
          <div className="level-up-description">
            {levelUpInfo?.locale_subtitle}
          </div>

          <div className="level-up-badge-container">
            <img className="level-up-badge" src={levelUpInfo?.badge} />
          </div>

          <div className="level-up-note">{t("levelling.you_levelled_up")}</div>

          <div className="got-it-button" onClick={onCloseDialog}>
            {t("common.got_it")}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LevelUpDialog;
