// General
import "./inbox-page-banner-tester.scss";
import { useState, useRef } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Redux
import { useDispatch } from "react-redux";
import {
  updateOnboardingPrivateRoomDaddyDialog,
  updateUpdateTorDialog,
} from "../../../../redux/store/dialogStore";
// Material UI
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
} from "@mui/material";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Banner from "../../../shared/elements/banner/banner";

const InboxPageBannerTester = () => {
  // General variables
  const swiperRef = useRef(null);
  const [timerObj, setTimerObj] = useState([]);
  const [showTester, setShowTester] = useState(false);
  const [InboxImageArray, setInboxImageArray] = useState([]);

  // Redux variables
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Event Handlers | Button
  const onShowTester = () => {
    setShowTester(!showTester);
  };
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const imageData = await readFile(file);

        setInboxImageArray((prevState) => [
          ...prevState,
          {
            asset_url: imageData,
            constant: "",
            cta: "",
            display_interval: "",
            type: "",
            url: "",
            view: "",
          },
        ]);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onRemoveBanner = (index) => {
    const newBannerArray = [...InboxImageArray];
    newBannerArray.splice(index, 1);
    setInboxImageArray(newBannerArray);
  };
  const onSaveBanner = (index) => {
    organizeUserAioTimerObj(InboxImageArray);
  };
  const onOpenLink = (banner) => {
    if (banner?.cta === "premium") {
      onNavigate(routeConst.upgrade.path);
    } else if (banner?.url) {
      window.open(banner?.url, "_blank");
    }
  };

  // Event Handlers | Input
  const onInputChange = (event, index, type) => {
    let tempData = [...InboxImageArray];

    switch (type) {
      case "constant":
      case "cta":
      case "display_interval":
      case "type":
      case "url":
      case "view":
        tempData[index][type] = event.target.value;
        setInboxImageArray(tempData);

        organizeUserAioTimerObj(tempData);
        break;
      default:
        break;
    }
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const organizeUserAioTimerObj = (banners) => {
    let newArr = [];

    banners.map((banner, index) => {
      newArr.push({
        index: index,
        hasTimer: banner?.countdown_data ? true : false,
        endEpoch: banner?.countdown_data?.countdown_end_at_epoch,
        label: banner?.countdown_data?.copies,
        timerInterval: null,
        countdown: null,
      });
    });

    newArr.map((item, index) => {
      if (item.hasTimer) {
        let today = moment();
        let end = moment.unix(item.endEpoch);

        item.timerInterval = setInterval(() => {
          let readableDate = getReadableDateFromEpoch(item.endEpoch);

          // Clear Interval if Epoch is less than 0
          if (item.endEpoch < 0) {
            clearInterval(item.timerInterval);
          }

          // Set Countdown
          if (today.isAfter(end)) {
            item.countdown = item?.label;
            clearInterval(item.timerInterval);
          } else {
            item.countdown = `${t("common.ends_in")} ${t("common.n_day", {
              n: readableDate.days,
            })} ${readableDate.hrs}:${
              readableDate.mnts > 9
                ? readableDate.mnts
                : `0${readableDate.mnts}`
            }:${
              readableDate.seconds > 9
                ? readableDate.seconds
                : `0${readableDate.seconds}`
            }`;
          }

          // Set Countdown to DOM
          // Reason we use DOM injection is because Carousel does not update after it is initialized
          const timerContainers = document.getElementsByClassName(
            "tester-user-aio-timer-container"
          );
          if (
            timerContainers.length > index &&
            item &&
            item.countdown !== undefined
          ) {
            timerContainers[index].innerHTML = item.countdown;
          }
        }, 1000);
      }
    });

    setTimerObj(newArr);
  };
  const getReadableDateFromEpoch = (epoch) => {
    // Convert Epoch to Seconds
    let epochToSeconds = epoch - moment().unix();

    // Convert Seconds to Days, Hours, Minutes, Seconds
    const days = Math.floor(epochToSeconds / (3600 * 24));
    epochToSeconds -= days * 3600 * 24;
    const hrs = Math.floor(epochToSeconds / 3600);
    epochToSeconds -= hrs * 3600;
    const mnts = Math.floor(epochToSeconds / 60);
    epochToSeconds -= mnts * 60;
    const seconds = epochToSeconds;

    return { days, hrs, mnts, seconds };
  };

  return (
    <div className="inbox-page-banner-container">
      <div className="page-label">
        <div
          className="inbox-banner-tester-icon-container"
          onClick={onShowTester}
        >
          <BuildCircleIcon />
        </div>
        <div className="inbox-label">Inbox/Interest Page</div>
      </div>

      <div className="inbox-page-banner">
        <Banner type={"inbox"} view={"carousel"} />
      </div>

      {showTester && (
        <div className="tester-container">
          <Button
            className="test-banner-button"
            disableRipple
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={onFilePicker}
            />
            <div>Upload Banner</div>
          </Button>

          <div className="inbox-page-banner">
            <div className="preview">Preview</div>

            <div id="banner-subcomponent">
              <div className="carousel-container border-radius">
                <swiper-container
                  ref={swiperRef}
                  class="swiper-container"
                  navigation="false"
                  pagination={InboxImageArray?.length > 1 ? "true" : "false"}
                  scrollbar="false"
                  // loop="true"
                  speed="300"
                  autoplay-delay="2500"
                >
                  {InboxImageArray?.map((banner, index) => (
                    <swiper-slide
                      class="banner-container"
                      onClick={() => onOpenLink(banner)}
                      key={index}
                    >
                      <img className="banner-image" src={banner?.asset_url} />
                      <div className="user-aio-timer-container"></div>
                    </swiper-slide>
                  ))}
                </swiper-container>
              </div>
            </div>

            <div>Backend Use only</div>

            <div className="banner-info-container">
              {InboxImageArray?.map((banner, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {`Banner ${index + 1}`}
                  </AccordionSummary>

                  <AccordionDetails>
                    <div className="banner-data-info-container">
                      <div className="banner-data-label-container">
                        {/* Constant */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.constant}
                            onChange={(event) =>
                              onInputChange(event, index, "constant")
                            }
                            label="constant"
                            variant="outlined"
                          />
                        </div>

                        {/* CTA */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.cta}
                            onChange={(event) =>
                              onInputChange(event, index, "cta")
                            }
                            label="cta"
                            variant="outlined"
                          />
                        </div>

                        {/* Display Interval */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.display_interval}
                            onChange={(event) =>
                              onInputChange(event, index, "display_interval")
                            }
                            label="display_interval"
                            variant="outlined"
                          />
                        </div>

                        {/* Type */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.type}
                            onChange={(event) =>
                              onInputChange(event, index, "type")
                            }
                            label="type"
                            variant="outlined"
                          />
                        </div>

                        {/* Type */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.view}
                            onChange={(event) =>
                              onInputChange(event, index, "view")
                            }
                            label="view"
                            variant="outlined"
                          />
                        </div>
                      </div>

                      <div className="banner-data-button-container">
                        <CloseIcon
                          className="remove-button"
                          onClick={() => onRemoveBanner(index)}
                        />
                        <SaveIcon
                          className="save-button"
                          onClick={() => onSaveBanner(index)}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InboxPageBannerTester;
