// General
import "./user-card.scss";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// Services
import {
  spiApi,
  useLazyGetPrivateCallStandbyPreJoinQuery,
  useFavoriteUnfavoriteUserMutation,
} from "../../../../services/data.service";
import { dashToUnderscore } from "../../../../services/regex.service";
// Static Data
import routeConst from "../../../../const/routeConst";
import userCardConst from "../../../../const/userCardConst";
import userConst from "../../../../const/userConst";
import searchConst from "../../../../const/searchConst";
// Redux
import { useDispatch } from "react-redux";
import { updateLocalSearchList } from "../../../../redux/store/searchStore";
import { updateLocalLivestreamList } from "../../../../redux/store/livestreamingStore";
import { updateLocalVideoCallList } from "../../../../redux/store/privateCallStore";
import {
  updateBusyInPrivateDialog,
  updateGoPrivatePermissionDialog,
  updatePreJoinDialog,
  updatePreJoinSugarBabyWarningDialog,
  updateVerifiedCallerDescriptionDialog,
  updateJoinPrivateAndroidWarningDialog,
  updateOutOfCoinsDialog,
} from "../../../../redux/store/dialogStore";
import {
  // User View
  updateId,
  updateIdInt,
  updateUsername,
  updateAge,
  updateGender,
  updateLocation,
  updateMembershipType,
  updateVerifiedProfile,
  updateVerifiedCaller,
  updateLevellingBadge,
  updateLivestreamingAchievementBadge,
  updateSpecialBadges,
  updateProfilePhoto,
  updateProfileDecorations,
  updatePublicPhotos,
  updatePrivatePhotos,
  updateProfileAchievementBadge,
  updateTorTags,
  updateDatingStyles,
  updateAboutMe,
  updateAboutMeManualText,
  updateAboutMeReadStatus,
  updateLookingFor,
  updateTagline,
  updateAnnualIncome,
  updateNetworth,
  updateOccupation,
  updateLifestyle,
  updateEducation,
  updateRelationship,
  updateSmoking,
  updateDrinking,
  updateHeight,
  updateBodyType,
  updateEthnicity,
  updateEyeColor,
  updateHairColor,
  updateChildren,

  // About Me Functions
  updatePersonalityType,
  updateHobbies,
  updateFitnessLevel,
  updateStarSign,
  updateDominantOrSubmissive,
  updatePersonalityTraits,

  // User Utility
  updateShouldCallApi,
  updatePrivatePhotoAbleToRequest,
  updatePrivatePhotoRequested,
  updatePrivatePhotoApproval,
  updateRequestedMyPrivatePhoto,
  updateApprovedMyPrivatePhoto,
  updateIsOnline,
  updateIsBlocked,
  updateIsSilentInteraction,
  updateIsFavorited,
  updateIsCallEnabled,
  updateIsSugarbaby,
  updateRole,
  updateProfileStatus,
} from "../../../../redux/store/userCardStore";
import {
  // Main Page Functions
  resetVideoCallSearchLists,

  // General Functions
  updateIsCaller,

  // Callee Functions
  updateCalleeId,
  updateCalleeUsername,
  updateCalleeProfilePhoto,
} from "../../../../redux/store/privateCallStore";
import {
  updateChannelId,
  updateLivestreamerId,
  updateLivestreamerIdInt,
  updateLivestreamerProfilePhoto,
  updateLivestreamerAchievements,
  updateLivestreamerUsername,
  updateLivestreamerLevellingBadge,
  updateLivestreamerFollowers,
  updateLivestreamerViewers,
  updateLivestreamerDiamonds,
  updateLivestreamerIsFollowing,
} from "../../../../redux/store/livestreamingStore";
import {
  updateErrorToast,
  updateWarningToast,
} from "../../../../redux/store/toastStore";
// react-gtm-module
import TagManager from "react-gtm-module";
// hls.js
import Hls from "hls.js";
// react-device-detect
import { isAndroid, isDesktop } from "react-device-detect";
// Material UI
import { useMediaQuery } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
// i18next
import { useTranslation } from "react-i18next";
// Custom Hooks
import useIsMounted from "../../../utility/custom-hooks/useIsMounted-hook";
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
import IconManager from "../../../utility/manager/icon-manager/icon-manager";
// Components
import CustomAvatar from "../custom-avatar/custom-avatar";
import Spinner from "../spinner/spinner";
import TermsOfRelationship from "../profile/terms-of-relationship/terms-of-relationship";

const UserCard = (props) => {
  const {
    // Settings
    cardView = searchConst.userCardDisplay.grid, // list, grid

    // User View
    id,
    idInt,
    username,
    age,
    gender,
    location,
    membershipType,
    verifiedProfile,
    verifiedCaller,
    levellingBadge,
    livestreamingAchievementBadge,
    specialBadges,
    profilePhoto,
    profileDecorations,
    publicPhotos,
    privatePhotos,
    torTags,
    datingStyle,
    profileAchievementBadge,
    aboutMe,
    aboutMeReadStatus,
    lookingFor,
    tagline,
    annualIncome,
    networth,
    occupation,
    lifestyle,
    education,
    relationship,
    smoking,
    drinking,
    height,
    bodyType,
    ethnicity,
    eyeColor,
    hairColor,
    children,
    withinDistanceInKm,
    // About Me
    personalityTraits,
    personalityType,
    starSign,
    dominantOrSubmissive,
    fitnessLevel,
    hobbies,
    // User Utility
    profileStatus,
    privatePhotoAbleToRequest,
    privatePhotoRequested,
    privatePhotoApproval,
    requestedMyPrivatePhoto,
    approvedMyPrivatePhoto,
    isOnline,
    isBlocked,
    isSilentInteraction,
    isFavorited,
    isCallEnabled,
    isSugarbaby,
    role,
    interest,
    isLgbtqInterested,
    // Livestream
    livestreamData,
    channelId,
    livestreamerId,
    followerCount,
    viewerCount,
    diamondCount,
    isFollowing,
    // Card Utility
    cardStatus,
  } = props;

  // API variables
  const [
    getPrivateCallStandbyPreJoin,
    {
      data: getPrivateCallStandbyPreJoinData,
      error: getPrivateCallStandbyPreJoinErrorData,
      isFetching: getPrivateCallStandbyPreJoinFetching,
      isLoading: getPrivateCallStandbyPreJoinLoading,
      isSuccess: getPrivateCallStandbyPreJoinSuccess,
      isError: getPrivateCallStandbyPreJoinError,
    },
  ] = useLazyGetPrivateCallStandbyPreJoinQuery();
  const [
    favoriteUnfavoriteUser,
    {
      data: favoriteUnfavoriteUserData,
      isLoading: favoriteUnfavoriteUserLoading,
      isSuccess: favoriteUnfavoriteUserSuccess,
      isError: favoriteUnfavoriteUserError,
    },
  ] = useFavoriteUnfavoriteUserMutation();

  // General variables
  const [hls, setHls] = useState(null);
  const [onShowVideo, setOnShowVideo] = useState(false);
  const liveCardVideoPlayer = useRef(null);

  // Redux variables
  const dispatch = useDispatch();

  // MUI variables
  const isMobile = useMediaQuery("(max-width: 455px)");

  // i18next variables
  const { t } = useTranslation();

  // Utility variables
  const regex = dashToUnderscore;

  // Custom Hooks Functions
  const mounted = useIsMounted();
  const onNavigate = useCustomNavigate();
  const getIcon = IconManager();

  // Lifecycle | Unmounted
  useEffect(() => {
    return () => {
      if (!mounted()) {
        if (hls) {
          hls.destroy();
        }
      }
    };
  }, [mounted]);

  // Lifecycle | Private Call Standby Pre Join API Response
  useEffect(() => {
    if (
      getPrivateCallStandbyPreJoinFetching ||
      getPrivateCallStandbyPreJoinLoading
    ) {
    } else if (getPrivateCallStandbyPreJoinSuccess) {
      switch (getPrivateCallStandbyPreJoinData?.status) {
        case 0:
          if (
            getPrivateCallStandbyPreJoinData?.data?.standby?.status === "busy"
          ) {
            // Callee is busy in another private call
            dispatch(updateBusyInPrivateDialog(true));

            // Reset Video Call List
            dispatch(resetVideoCallSearchLists());

            // Refetch Video Call List
            dispatch({
              type: `${spiApi.reducerPath}/invalidateTags`,
              payload: ["VideoCallSearch"],
            });
          } else {
            onOpenDialog("pre-join-private-call");
          }
          break;
        case -1:
          // Someone else is already in the private call
          const toastObj = {
            message: getPrivateCallStandbyPreJoinData?.message,
            autoClose: 3000,
          };
          dispatch(updateErrorToast(toastObj));

          // Reset Video Call List
          dispatch(resetVideoCallSearchLists());

          // Refetch Video Call List
          dispatch({
            type: `${spiApi.reducerPath}/invalidateTags`,
            payload: ["VideoCallSearch"],
          });
          break;
        case -10:
          dispatch(updateOutOfCoinsDialog(true));
          break;
        case -99:
          // Kicked Out
          const warningToastObj = {
            message: getPrivateCallStandbyPreJoinData?.message,
            autoClose: 3000,
          };
          dispatch(updateWarningToast(warningToastObj));
          break;
        case -1003:
          // Sugar baby cannot join another sugarbaby's private call
          onOpenDialog("not-allow-sugar-baby");
          break;
        default:
          break;
      }
    } else if (getPrivateCallStandbyPreJoinError) {
      if (getPrivateCallStandbyPreJoinErrorData?.status === 401) {
        onNavigate(routeConst.logout.path);
      }
    }
  }, [
    getPrivateCallStandbyPreJoinFetching,
    getPrivateCallStandbyPreJoinLoading,
    getPrivateCallStandbyPreJoinSuccess,
    getPrivateCallStandbyPreJoinError,
  ]);

  // Lifecycle | Check for update | Favorite Unfavorite User API Response
  useEffect(() => {
    if (favoriteUnfavoriteUserLoading) {
    } else if (favoriteUnfavoriteUserSuccess) {
      switch (favoriteUnfavoriteUserData?.status) {
        case 1:
          dispatch(updateIsFavorited(!isFavorited));

          // Update local view
          const obj = {
            idInt,
            privatePhotoRequested,
            isBlocked,
            isFavorited: !isFavorited,
          };
          dispatch(updateLocalSearchList(obj));
          dispatch(updateLocalLivestreamList(obj));
          // dispatch(updateLocalVideoCallList(obj)); // Deprecated // Not calling video call API anymore
          break;
        default:
          break;
      }
    } else if (favoriteUnfavoriteUserError) {
    }
  }, [
    favoriteUnfavoriteUserLoading,
    favoriteUnfavoriteUserSuccess,
    favoriteUnfavoriteUserError,
  ]);

  // Lifecycle | Check for update | Hls
  useEffect(() => {
    if (!hls) return;

    hls.loadSource(getStreamPullChannel());

    // bind them together
    hls.attachMedia(liveCardVideoPlayer.current);
  }, [hls]);

  // Event Handlers | Button
  const onUserClick = (type) => {
    if (cardStatus === userCardConst.cardStatus.default) {
      switch (profileStatus) {
        case userCardConst.userProfileStatus.inPrivateAvailable:
        case userCardConst.userProfileStatus.inPrivateBusy:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-S4-4.3-Button",
            },
          });

          if (isAndroid) {
            // For Android devices using PWA, ask user to download Android APK
            dispatch(updateJoinPrivateAndroidWarningDialog(true));
          }

          dispatch(updateCalleeId(id));
          dispatch(updateCalleeUsername(username));
          dispatch(updateCalleeProfilePhoto(profilePhoto));

          const preJoinBody = {
            user_id: id,
          };
          getPrivateCallStandbyPreJoin(preJoinBody);
          break;
        case userCardConst.userProfileStatus.videoCall:
        case userCardConst.userProfileStatus.normal:
        case userCardConst.userProfileStatus.live:
        case userCardConst.userProfileStatus.coAnchor:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-S4-4.1-Button",
            },
          });

          // User View
          dispatch(updateId(id));
          dispatch(updateIdInt(idInt));
          dispatch(updateUsername(username));
          dispatch(updateAge(age));
          dispatch(updateGender(gender));
          dispatch(updateLocation(location));
          dispatch(updateMembershipType(membershipType));
          dispatch(updateVerifiedProfile(verifiedProfile));
          dispatch(updateVerifiedCaller(verifiedCaller));
          dispatch(updateLevellingBadge(levellingBadge));
          dispatch(
            updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
          );
          dispatch(updateSpecialBadges(specialBadges));
          dispatch(updateProfilePhoto(profilePhoto));
          dispatch(updateProfileDecorations(profileDecorations));
          dispatch(updatePublicPhotos(publicPhotos));
          dispatch(updatePrivatePhotos(privatePhotos));
          dispatch(updateProfileAchievementBadge(profileAchievementBadge));
          dispatch(updateTorTags(torTags));
          dispatch(updateDatingStyles(datingStyle));
          dispatch(updateAboutMeManualText(aboutMe));
          dispatch(updateAboutMeReadStatus(aboutMeReadStatus));
          dispatch(updateAnnualIncome(annualIncome));
          dispatch(updateNetworth(networth));
          dispatch(updateOccupation(occupation));
          dispatch(updateLifestyle(lifestyle));
          dispatch(updateEducation(education));
          dispatch(updateRelationship(relationship));
          dispatch(updateSmoking(smoking));
          dispatch(updateDrinking(drinking));
          dispatch(updateHeight(height));
          dispatch(updateBodyType(bodyType));
          dispatch(updateEthnicity(ethnicity));
          dispatch(updateEyeColor(eyeColor));
          dispatch(updateHairColor(hairColor));
          dispatch(updateChildren(children));

          // About Me
          let tempAboutMeList = [];
          tempAboutMeList?.push({
            name: "personality_type",
            data: personalityType?.length > 0 ? personalityType : null,
          });
          tempAboutMeList?.push({
            name: "hobbies",
            data: hobbies?.lengtj > 0 ? hobbies : null,
          });
          tempAboutMeList?.push({
            name: "star_sign",
            data: starSign,
          });
          tempAboutMeList?.push({
            name: "fitness_level",
            data: fitnessLevel,
          });
          tempAboutMeList?.push({
            name: "dominant_or_submissive",
            data: dominantOrSubmissive,
          });
          tempAboutMeList?.push({
            name: "personality_traits",
            data: personalityTraits,
          });
          dispatch(updatePersonalityType(personalityType));
          dispatch(updateHobbies(hobbies));
          dispatch(updateFitnessLevel(fitnessLevel));
          dispatch(updateStarSign(starSign));
          dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
          dispatch(updatePersonalityTraits(personalityTraits));
          dispatch(updateAboutMe(tempAboutMeList));

          // User Utility
          dispatch(updateShouldCallApi(true));
          dispatch(updateProfileStatus(profileStatus));
          dispatch(updatePrivatePhotoAbleToRequest(privatePhotoAbleToRequest));
          dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
          dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
          dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
          dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
          dispatch(updateIsOnline(isOnline));
          dispatch(updateIsBlocked(isBlocked ? true : false));
          dispatch(updateIsSilentInteraction(isSilentInteraction));
          dispatch(updateIsFavorited(isFavorited));
          dispatch(updateIsCallEnabled(isCallEnabled));
          dispatch(updateIsSugarbaby(isSugarbaby));
          dispatch(updateRole(role));
          dispatch(updateLookingFor(interest));

          // Navigate to Profile View
          if (type !== "link") {
            onNavigate(`${routeConst.profile.view.clearPath}${id}`);
          }
          // dispatch(updateUserProfileDialog(true)) // Dialog
          break;
        default:
          break;
      }
    } else {
      switch (profileStatus) {
        case userCardConst.userProfileStatus.live:
        case userCardConst.userProfileStatus.coAnchor:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-S4-4.2-Button",
            },
          });

          dispatch(updateChannelId(channelId));
          dispatch(updateLivestreamerId(livestreamerId));
          dispatch(updateLivestreamerIdInt(idInt));
          dispatch(updateLivestreamerProfilePhoto(profilePhoto));
          dispatch(
            updateLivestreamerAchievements(livestreamingAchievementBadge)
          );
          dispatch(updateLivestreamerUsername(username));
          dispatch(updateLivestreamerLevellingBadge(levellingBadge));
          dispatch(updateLivestreamerFollowers(followerCount));
          dispatch(updateLivestreamerViewers(viewerCount));
          dispatch(updateLivestreamerDiamonds(diamondCount));
          dispatch(updateLivestreamerIsFollowing(isFollowing));

          if (channelId) {
            onNavigate(`${routeConst.live.liveId.clearPath}${channelId}`);
          } else {
            const warningToastObj = {
              message: t("common.something_went_wrong"),
              autoClose: 3000,
            };
            dispatch(updateWarningToast(warningToastObj));
          }

          break;
        case userCardConst.userProfileStatus.inPrivateAvailable:
        case userCardConst.userProfileStatus.inPrivateBusy:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-S4-4.3-Button",
            },
          });

          if (isAndroid) {
            // For Android devices using PWA, ask user to download Android APK
            dispatch(updateJoinPrivateAndroidWarningDialog(true));
          }

          dispatch(updateCalleeId(id));
          dispatch(updateCalleeUsername(username));
          dispatch(updateCalleeProfilePhoto(profilePhoto));

          const preJoinBody = {
            user_id: id,
          };
          getPrivateCallStandbyPreJoin(preJoinBody);
          break;
        case userCardConst.userProfileStatus.videoCall:
        case userCardConst.userProfileStatus.normal:
          TagManager.dataLayer({
            dataLayer: {
              event: "PWA-S4-4.1-Button",
            },
          });

          // User View
          dispatch(updateId(id));
          dispatch(updateIdInt(idInt));
          dispatch(updateUsername(username));
          dispatch(updateAge(age));
          dispatch(updateGender(gender));
          dispatch(updateLocation(location));
          dispatch(updateMembershipType(membershipType));
          dispatch(updateVerifiedProfile(verifiedProfile));
          dispatch(updateVerifiedCaller(verifiedCaller));
          dispatch(updateLevellingBadge(levellingBadge));
          dispatch(
            updateLivestreamingAchievementBadge(livestreamingAchievementBadge)
          );
          dispatch(updateSpecialBadges(specialBadges));
          dispatch(updateProfilePhoto(profilePhoto));
          dispatch(updateProfileDecorations(profileDecorations));
          dispatch(updatePublicPhotos(publicPhotos));
          dispatch(updatePrivatePhotos(privatePhotos));
          dispatch(updateProfileAchievementBadge(profileAchievementBadge));
          dispatch(updateTorTags(torTags));
          dispatch(updateAboutMeManualText(aboutMe));
          dispatch(updateAboutMeReadStatus(aboutMeReadStatus));
          dispatch(updateAnnualIncome(annualIncome));
          dispatch(updateNetworth(networth));
          dispatch(updateOccupation(occupation));
          dispatch(updateLifestyle(lifestyle));
          dispatch(updateEducation(education));
          dispatch(updateRelationship(relationship));
          dispatch(updateSmoking(smoking));
          dispatch(updateDrinking(drinking));
          dispatch(updateHeight(height));
          dispatch(updateBodyType(bodyType));
          dispatch(updateEthnicity(ethnicity));
          dispatch(updateEyeColor(eyeColor));
          dispatch(updateHairColor(hairColor));
          dispatch(updateChildren(children));
          // About Me
          let tempAboutMeList = [];
          tempAboutMeList?.push({
            name: "personality_type",
            data: personalityType?.length > 0 ? personalityType : null,
          });
          tempAboutMeList?.push({
            name: "hobbies",
            data: hobbies?.lengtj > 0 ? hobbies : null,
          });
          tempAboutMeList?.push({
            name: "star_sign",
            data: starSign,
          });
          tempAboutMeList?.push({
            name: "fitness_level",
            data: fitnessLevel,
          });
          tempAboutMeList?.push({
            name: "dominant_or_submissive",
            data: dominantOrSubmissive,
          });
          tempAboutMeList?.push({
            name: "personality_traits",
            data: personalityTraits,
          });
          dispatch(updatePersonalityType(personalityType));
          dispatch(updateHobbies(hobbies));
          dispatch(updateFitnessLevel(fitnessLevel));
          dispatch(updateStarSign(starSign));
          dispatch(updateDominantOrSubmissive(dominantOrSubmissive));
          dispatch(updatePersonalityTraits(personalityTraits));
          dispatch(updateAboutMe(tempAboutMeList));
          // User Utility
          dispatch(updateShouldCallApi(true));
          dispatch(updateProfileStatus(profileStatus));
          dispatch(updatePrivatePhotoAbleToRequest(privatePhotoAbleToRequest));
          dispatch(updatePrivatePhotoRequested(privatePhotoRequested));
          dispatch(updatePrivatePhotoApproval(privatePhotoApproval));
          dispatch(updateRequestedMyPrivatePhoto(requestedMyPrivatePhoto));
          dispatch(updateApprovedMyPrivatePhoto(approvedMyPrivatePhoto));
          dispatch(updateIsOnline(isOnline));
          dispatch(updateIsBlocked(isBlocked ? true : false));
          dispatch(updateIsSilentInteraction(isSilentInteraction));
          dispatch(updateIsFavorited(isFavorited));
          dispatch(updateIsCallEnabled(isCallEnabled));
          dispatch(updateIsSugarbaby(isSugarbaby));
          dispatch(updateRole(role));
          dispatch(updateLookingFor(interest));

          // Navigate to Profile View
          if (type !== "link") {
            onNavigate(`${routeConst.profile.view.clearPath}${id}`);
          }
          // dispatch(updateUserProfileDialog(true)) // Dialog
          break;
        default:
          break;
      }
    }
  };
  const onFavoriteProfile = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "PWA-S4-4.4-Button",
      },
    });

    const obj = {
      user_id: id,
      favorite: !isFavorited,
    };
    favoriteUnfavoriteUser(obj);
  };
  const onOpenVerifiedCallerDialog = () => {
    dispatch(updateVerifiedCallerDescriptionDialog(true));
  };

  // Event Handlers | MUI Dialog
  const onOpenDialog = (view) => {
    if (view === "go-private-permission") {
      dispatch(updateIsCaller(true));
      dispatch(updateGoPrivatePermissionDialog(true));
    } else if (view === "pre-join-private-call") {
      dispatch(updateIsCaller(true));
      dispatch(updatePreJoinDialog(true));
    } else if (view === "not-allow-sugar-baby") {
      dispatch(updateIsCaller(false));
      dispatch(updatePreJoinSugarBabyWarningDialog(true));
    }
  };

  // Utility Functions
  const convertToMultiLanguage = (output, index) => {
    let result = "-";

    if (output && output !== "-") {
      // Convert to lower case except height
      let outputLowerCase;
      if (index !== 6 && index !== 11) {
        outputLowerCase = output.toLowerCase();
      }

      switch (index) {
        case 0: // occupation
          result = output;
          break;
        case 1: // lifestyle
          result = t(
            `filter.opt_lifestyle.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 2: // education
          result = t(
            `filter.opt_education.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 3: // relationship
          result = t(
            `filter.opt_relationship.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 4: // smoking
          result = t(
            `filter.opt_smoking.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 5: // drinking
          result = t(
            `filter.opt_drinking.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 6: // height
          result = `${output} cm`;
          break;
        case 7: // bodyType
          result = t(
            `filter.opt_body_type.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 8: // ethnicity
          result = t(
            `filter.opt_ethnicity.${outputLowerCase.replace(regex, "_")}`
          );
          break;
        case 9: // eyeColor
          result = t(`filter.opt_color.${outputLowerCase.replace(regex, "_")}`);
          break;
        case 10: // hairColor
          result = t(`filter.opt_color.${outputLowerCase.replace(regex, "_")}`);
          break;
        case 11: // children
          result = output;
          break;
        default:
          break;
      }
    }

    return result;
  };
  const handleMouseEnter = () => {
    if (
      isDesktop &&
      profileStatus === userCardConst.userProfileStatus.live &&
      livestreamData?.pull?.length > 0 &&
      !hls
    ) {
      addDefaultListenerToVideoPlayer();

      if (Hls.isSupported()) {
        setHls(
          new Hls({
            // Set the maximum buffer length in seconds
            maxBufferLength: 30,

            // Set the maximum buffer size in MB
            maxBufferSize: 60 * 1000 * 1000,

            // Set the maximum number of buffer stalls before downswitching quality
            highBufferWatchdogPeriod: 2,

            // Set the maximum number of buffer stalls before upswitching quality
            lowBufferWatchdogPeriod: 0.5,

            // Enable or disable worker for transmuxing
            enableWorker: true,

            liveSyncDuration: 3,
          })
        );
      } else {
        if (
          liveCardVideoPlayer.current.canPlayType(
            "application/vnd.apple.mpegURL"
          ) ||
          liveCardVideoPlayer.current.canPlayType(
            "application/vnd.apple.mpegurl"
          )
        ) {
          liveCardVideoPlayer.current.src = getStreamPullChannel();
        }
      }
    }
  };
  const handleMouseLeave = () => {
    setOnShowVideo(false);

    if (hls) {
      hls.destroy();
      setHls(null);
    }
  };
  const getStreamPullChannel = () => {
    const hlsChannel = livestreamData?.pull?.find(
      (channel) => channel.type === "hls"
    );
    return hlsChannel?.url || null;
  };
  const addDefaultListenerToVideoPlayer = () => {
    const events = ["canplaythrough"];

    let eventFunction = function (e) {
      // Event Listener if video recovers from stalled state
      switch (e.type) {
        case "canplaythrough":
          setOnShowVideo(true);
          break;
        default:
          break;
      }
    };

    events.forEach((e) => {
      liveCardVideoPlayer.current.addEventListener(
        "canplaythrough",
        eventFunction,
        false
      );
    });
  };
  const returnBorderGradient = () => {
    if (profileDecorations?.card_border_gradient_color) {
      return "border-gradient";
    } else if (
      profileStatus === userCardConst.userProfileStatus.inPrivateAvailable
    ) {
      return "private-border-gradient";
    } else {
      return "";
    }
  };

  return (
    <div id="user-card-shared-subcomponent">
      {cardView === searchConst.userCardDisplay.grid && (
        <div
          className={`relative ${returnBorderGradient()}`}
          style={{
            background: `linear-gradient(90deg, ${profileDecorations?.card_border_gradient_color?.color_one}, ${profileDecorations?.card_border_gradient_color?.color_two}, ${profileDecorations?.card_border_gradient_color?.color_three})`,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="search-card-container">
            <div className="profile-image-container">
              <CustomAvatar
                className="profile-image"
                src={profilePhoto}
                role={role}
                profileBorder={null} // Not used in User Card
              />
            </div>

            <div className="description-container">
              {cardStatus === userCardConst.cardStatus.default ? (
                <Link
                  className="top-description-container"
                  to={`${routeConst.profile.view.clearPath}${id}`}
                  onClick={() => onUserClick("link")}
                >
                  {/* User Status - Live */}
                  {(profileStatus === userCardConst.userProfileStatus.live ||
                    profileStatus ===
                      userCardConst.userProfileStatus.coAnchor) && (
                    <div className="live">
                      <div className="live-status">
                        {t("search.badge.live")}
                      </div>
                      <div className="viewer-count-container">
                        <div className="eye-logo-container">
                          <VisibilityIcon className="eye-logo" />
                        </div>
                        <div className="viewer-count">{viewerCount || 0}</div>
                      </div>
                    </div>
                  )}

                  {/* User Status - Available for Private Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.inPrivateAvailable && (
                    <div className="private-call-available">
                      {t("search.badge.private_room")}

                      {/* Verified Caller Badge */}
                      {verifiedCaller && (
                        <div
                          className="verified-caller-badge-container"
                          onClick={onOpenVerifiedCallerDialog}
                        >
                          {getIcon(
                            "verifiedCallerBadge",
                            "verified-caller-badge"
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* User Status - Busy in Private Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.inPrivateBusy && (
                    <div className="private-call-busy">
                      {t("search.badge.busy_in_private")}

                      {/* Verified Caller Badge */}
                      {verifiedCaller && (
                        <div className="verified-caller-badge-container">
                          {getIcon(
                            "verifiedCallerBadge",
                            "verified-caller-badge"
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* User Status - Video Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.videoCall && (
                    <div className="video-call">
                      {t("search.badge.video_call")}
                    </div>
                  )}

                  {isLgbtqInterested && (
                    <div className="top-right-profile-badge-container">
                      {getIcon("lgbtqIcon", "lgbtq-icon")}
                    </div>
                  )}
                </Link>
              ) : (
                <div
                  className="top-description-container"
                  onClick={() => onUserClick("default")}
                >
                  {/* User Status - Live */}
                  {(profileStatus === userCardConst.userProfileStatus.live ||
                    profileStatus ===
                      userCardConst.userProfileStatus.coAnchor) && (
                    <div className="live">
                      <div className="live-status">
                        {t("search.badge.live")}
                      </div>
                      <div className="viewer-count-container">
                        <div className="eye-logo-container">
                          <VisibilityIcon className="eye-logo" />
                        </div>
                        <div className="viewer-count">{viewerCount || 0}</div>
                      </div>
                    </div>
                  )}

                  {/* User Status - Available for Private Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.inPrivateAvailable && (
                    <div className="private-call-available">
                      {t("search.badge.private_room")}

                      {/* Verified Caller Badge */}
                      {verifiedCaller && (
                        <div
                          className="verified-caller-badge-container"
                          onClick={onOpenVerifiedCallerDialog}
                        >
                          {getIcon(
                            "verifiedCallerBadge",
                            "verified-caller-badge"
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* User Status - Busy in Private Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.inPrivateBusy && (
                    <div className="private-call-busy">
                      {t("search.badge.busy_in_private")}

                      {/* Verified Caller Badge */}
                      {verifiedCaller && (
                        <div className="verified-caller-badge-container">
                          {getIcon(
                            "verifiedCallerBadge",
                            "verified-caller-badge"
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {/* User Status - Video Call */}
                  {profileStatus ===
                    userCardConst.userProfileStatus.videoCall && (
                    <div className="video-call">
                      {t("search.badge.video_call")}
                    </div>
                  )}

                  {isLgbtqInterested && (
                    <div className="top-right-profile-badge-container">
                      {getIcon("lgbtqIcon", "lgbtq-icon")}
                    </div>
                  )}
                </div>
              )}

              <div className="bottom-description-container">
                <div className="left-detail-container">
                  {withinDistanceInKm && (
                    <div className="within-distance-container">
                      <div className="pin-icon-container">
                        <FmdGoodIcon className="pin-icon" />
                      </div>

                      <div className="distance-in-km-label">
                        {t("profile.n_km_away", { n: withinDistanceInKm })}
                      </div>
                    </div>
                  )}

                  <div className="badges-container">
                    {livestreamingAchievementBadge?.length > 0 && (
                      <div className="livestreaming-achievements-badge-container">
                        {livestreamingAchievementBadge?.map(
                          (achievement, index) => (
                            <img
                              className="livestreaming-achievements-badge"
                              src={achievement?.url}
                              alt={"badge"}
                              key={index}
                            />
                          )
                        )}
                      </div>
                    )}

                    {specialBadges?.length > 0 && (
                      <div className="special-badge-container">
                        {specialBadges?.map((badge, index) => (
                          <img
                            className="special-badge"
                            src={badge?.asset}
                            alt={"badge"}
                            key={index}
                          />
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="username-description">
                    <div className="username">
                      {username || ""}
                      {age && <span>,</span>}
                    </div>
                    {age && <div className="age">{age || ""}</div>}
                    {isOnline && <div className="status"></div>}

                    {/* Verified Profile */}
                    {verifiedProfile && (
                      <div className="verified-profile-badge-container">
                        {getIcon(
                          "verifiedProfileBadge",
                          "verified-profile-badge"
                        )}
                      </div>
                    )}

                    {/* Premium Mini Badge */}
                    {(membershipType === userConst.membershipType.premium ||
                      membershipType === userConst.membershipType.freePremium ||
                      membershipType === userConst.membershipType.diamond) && (
                      <div className="premium-mini-badge-container">
                        {getIcon("premiumMiniBadge", "premium-mini-badge")}
                      </div>
                    )}
                  </div>

                  <div className="location-description">
                    <div className="location">{location || ""}</div>
                    {levellingBadge && (
                      <div className="badge-container">
                        <img
                          className="badge"
                          src={levellingBadge}
                          alt={"badge"}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="right-detail-container">
                  {favoriteUnfavoriteUserLoading ? (
                    <Spinner size={20} isPadding={false} />
                  ) : (
                    <div
                      className="favorite-icon-container"
                      onClick={onFavoriteProfile}
                    >
                      {isFavorited
                        ? getIcon("favoritedUserCardIcon", "favorite-icon")
                        : getIcon("favoriteWhiteAltIcon", "favorite-icon")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {isDesktop &&
            profileStatus === userCardConst.userProfileStatus.live &&
            livestreamData?.pull?.length > 0 && (
              <div
                className="live-card-video-player-container"
                style={{ opacity: onShowVideo ? "1" : "0" }}
              >
                <video
                  id="live-card-video-player"
                  // className={`live-video ${getVideoPlayerStyle()}`}
                  ref={liveCardVideoPlayer}
                  playsInline
                  preload="off"
                  muted={true}
                  autoPlay
                ></video>
              </div>
            )}

          {profileDecorations?.card_border_url && (
            <div className="custom-card-border-container">
              <img
                className="custom-card-border"
                src={profileDecorations?.card_border_url}
                alt={"card-border"}
              />
            </div>
          )}
        </div>
      )}

      {cardView === searchConst.userCardDisplay.list && (
        <div className="search-list-container">
          {/* Profile Photo */}
          <div
            className={`photo-container ${isMobile ? "larger" : ""}`}
            onClick={() => onUserClick("default")}
          >
            <div className="profile-photo-container">
              <CustomAvatar
                className="profile-photo"
                src={profilePhoto}
                role={role}
                profileBorder={profileDecorations?.profile_border_url}
              />

              {favoriteUnfavoriteUserLoading ? (
                <Spinner size={20} />
              ) : (
                <div
                  className="favorite-icon-container"
                  onClick={onFavoriteProfile}
                >
                  {isFavorited
                    ? getIcon("favoritedUserCardIcon", "favorite-icon")
                    : getIcon("favoriteWhiteAltIcon", "favorite-icon")}
                </div>
              )}
            </div>
          </div>

          {/* Profile Details */}
          <div className={`details-container ${isMobile ? "smaller" : ""}`}>
            <div className="basic-details-container">
              <div className="details-container">
                <div className="name-age-container">
                  {/* Username */}
                  <div className="username">{username || "-"},</div>

                  {/* Age */}
                  <div className="age">{age || "-"}</div>

                  {/* Verified Profile */}
                  {verifiedProfile && (
                    <div className="verified-profile-badge-container">
                      {getIcon(
                        "verifiedProfileBadge",
                        "verified-profile-badge"
                      )}
                    </div>
                  )}

                  {/* Verified Caller */}
                  {verifiedCaller && (
                    <div className="verified-caller-badge-container">
                      {getIcon("verifiedCallerBadge", "verified-caller-badge")}
                    </div>
                  )}

                  {/* Premium Mini Badge */}
                  {(membershipType === userConst.membershipType.premium ||
                    membershipType === userConst.membershipType.freePremium ||
                    membershipType === userConst.membershipType.diamond) && (
                    <div className="premium-mini-badge-container">
                      {getIcon("premiumMiniBadge", "premium-mini-badge")}
                    </div>
                  )}

                  <span className="spacer"></span>

                  {withinDistanceInKm && (
                    <div className="within-distance-container">
                      <div className="pin-icon-container">
                        <FmdGoodIcon className="pin-icon" />
                      </div>

                      <div className="distance-in-km-label">
                        {t("profile.n_km_away", { n: withinDistanceInKm })}
                      </div>
                    </div>
                  )}
                </div>

                {/* Location */}
                <div className="location">{location || "-"}</div>

                {/* About Me */}
                <div className="tagline">{tagline || "-"}</div>
              </div>
            </div>

            <div
              className={`indept-details-container ${isMobile ? "column" : ""}`}
            >
              <div className={`left-container ${isMobile ? "full-width" : ""}`}>
                {/* Height */}
                <div className="height-container">
                  <div className="height-label">{t("profile.height")}:</div>
                  <div className="height-value">{height || "-"}</div>
                </div>

                {/* Body Weight */}
                <div className="body-weight-container">
                  <div className="body-weight-label">
                    {t("profile.body_type")}:
                  </div>
                  <div className="body-weight-value">
                    {convertToMultiLanguage(bodyType, 7) || "-"}
                  </div>
                </div>
              </div>

              <div
                className={`right-container ${isMobile ? "full-width" : ""}`}
              >
                {/* Ethnicity */}
                <div className="ethnicity-container">
                  <div className="ethnicity-label">
                    {t("profile.ethnicity")}:
                  </div>
                  <div className="ethnicity-value">
                    {convertToMultiLanguage(ethnicity, 8) || "-"}
                  </div>
                </div>

                {/* Lifestyle */}
                <div className="lifestyle-container">
                  <div className="lifestyle-label">
                    {t("profile.lifestyle")}:
                  </div>
                  <div className="lifestyle-value">
                    {convertToMultiLanguage(lifestyle, 1) || "-"}
                  </div>
                </div>
              </div>
            </div>

            <div className="tor-details-container">
              <TermsOfRelationship
                enableTitle={false}
                isShared={true}
                sharedTorTags={torTags}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserCard;
