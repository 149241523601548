// General
import "./live-page-banner-tester.scss";
import { useState, useRef } from "react";
// Static Data
import routeConst from "../../../../const/routeConst";
// Material UI
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// i18next
import { useTranslation } from "react-i18next";
// Moment
import moment from "moment";
// Custom Hooks
import useCustomNavigate from "../../../utility/custom-hooks/useCustomNavigate-hook";
// Components
import Banner from "../../../shared/elements/banner/banner";

const LivePageBannerTester = () => {
  // General variables
  const swiperRef = useRef(null);
  const [timerObj, setTimerObj] = useState([]);
  const [showTester, setShowTester] = useState(false);
  const [liveImageArray, setLiveImageArray] = useState([]);

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const onNavigate = useCustomNavigate();

  // Event Handlers | Button
  const onShowTester = () => {
    setShowTester(!showTester);
  };
  const onFilePicker = async (event) => {
    const file = event.target.files[0];

    if (file !== undefined) {
      try {
        const imageData = await readFile(file);

        setLiveImageArray((prevState) => [
          ...prevState,
          {
            asset_url: imageData,
            countdown_end_at: "",
            countdown_end_at_epoch: "",
            countdown_font_color: "#FFF000",
            countdown_start_at: "",
            countdown_start_at_epoch: "",
            cta: "",
            is_countdown_required: false,
            reference: "",
            targeted_audiences: "",
            title_font_color: "#FFF000",
            type: "",
            url: "",

            simple_view: false,
            manual_timer_text: "",
          },
        ]);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onRemoveBanner = (index) => {
    const timerContainers = document.getElementsByClassName(
      "tester-livestream-timer-container"
    );

    timerContainers[index].innerHTML = "";

    const newBannerArray = [...liveImageArray];
    newBannerArray.splice(index, 1);
    setLiveImageArray(newBannerArray);
  };
  const onSaveBanner = (index) => {
    if (liveImageArray[index].simple_view) {
      const timerContainers = document.getElementsByClassName(
        "tester-livestream-timer-container"
      );

      timerContainers[index].innerHTML =
        liveImageArray[index].manual_timer_text;
    } else {
      organizeLivestreamTimerObj(liveImageArray);
    }
  };
  const onOpenLink = (banner) => {
    if (banner?.type === "game_entry") {
      onNavigate(routeConst.games.casino.path);
    } else if (banner?.url) {
      window.open(banner?.url, "_blank");
    }
  };

  // Event Handlers | Input
  const onInputChange = (event, index, type) => {
    let tempData = [...liveImageArray];

    switch (type) {
      case "countdown_end_at":
      case "countdown_end_at_epoch":
      case "countdown_font_color":
      case "countdown_start_at":
      case "countdown_start_at_epoch":
      case "cta":
      case "is_countdown_required":
      case "reference":
      case "targeted_audiences":
      case "title_font_color":
      case "type":
      case "url":
      case "manual_timer_text":
        tempData[index][type] = event.target.value;
        setLiveImageArray(tempData);

        organizeLivestreamTimerObj(tempData);
        break;
      default:
        break;
    }
  };

  // Event Handlers | Switch
  const onToggleIsCountdownRequired = (event, index) => {
    let tempData = [...liveImageArray];

    tempData[index].is_countdown_required = event.target.checked;

    setLiveImageArray(tempData);
  };
  const onToggleSimpleView = (event, index) => {
    let tempData = [...liveImageArray];
    tempData[index].simple_view = event.target.checked;
    setLiveImageArray(tempData);
  };

  // Utility Functions
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const organizeLivestreamTimerObj = (banners) => {
    const newTimerObj = banners?.map((banner, index) => {
      const hasTimer = banner?.is_countdown_required;
      const startEpoch = banner?.countdown_start_at_epoch;
      const endEpoch = banner?.countdown_end_at_epoch;
      let countdown = null;

      if (!hasTimer) {
        return {
          index,
          hasTimer,
          endEpoch,
          timerInterval: null,
          countdown,
        };
      }

      const timerInterval = setInterval(() => {
        const today = moment();
        const start = moment.unix(startEpoch);
        const end = moment.unix(endEpoch);
        const readableStartDate = getReadableDateFromEpoch(startEpoch);
        const readableEndDate = getReadableDateFromEpoch(endEpoch);

        // Clear Interval if Epoch is less than 0
        if (endEpoch < 0) {
          clearInterval(timerInterval);
          return;
        }

        // Set Countdown
        if (today.isAfter(end)) {
          countdown = t("common.contest_ended");
          clearInterval(timerInterval);
        } else {
          if (today.isBefore(start)) {
            countdown = `${t("common.starts_in")} ${t("common.n_day", {
              n: readableStartDate.days,
            })} ${readableStartDate.hrs}:${
              readableStartDate.mnts > 9
                ? readableStartDate.mnts
                : `0${readableStartDate.mnts}`
            }:${
              readableStartDate.seconds > 9
                ? readableStartDate.seconds
                : `0${readableStartDate.seconds}`
            }`;
          } else {
            countdown = `${t("common.ends_in")} ${t("common.n_day", {
              n: readableEndDate.days,
            })} ${readableEndDate.hrs}:${
              readableEndDate.mnts > 9
                ? readableEndDate.mnts
                : `0${readableEndDate.mnts}`
            }:${
              readableEndDate.seconds > 9
                ? readableEndDate.seconds
                : `0${readableEndDate.seconds}`
            }`;
          }
        }

        // Set Countdown to DOM
        const timerContainer = document.getElementsByClassName(
          "tester-livestream-timer-container"
        )[index];
        if (timerContainer) {
          timerContainer.innerHTML = countdown;
        }
      }, 1000);

      return {
        index,
        hasTimer,
        endEpoch,
        timerInterval,
        countdown,
      };
    });

    setTimerObj(newTimerObj);
  };
  const getReadableDateFromEpoch = (epoch) => {
    // Convert Epoch to Seconds
    let epochToSeconds = epoch - moment().unix();

    // Convert Seconds to Days, Hours, Minutes, Seconds
    const days = Math.floor(epochToSeconds / (3600 * 24));
    epochToSeconds -= days * 3600 * 24;
    const hrs = Math.floor(epochToSeconds / 3600);
    epochToSeconds -= hrs * 3600;
    const mnts = Math.floor(epochToSeconds / 60);
    epochToSeconds -= mnts * 60;
    const seconds = epochToSeconds;

    return { days, hrs, mnts, seconds };
  };

  return (
    <div className="live-page-banner-container">
      <div className="page-label">
        <div
          className="live-banner-tester-icon-container"
          onClick={onShowTester}
        >
          <BuildCircleIcon />
        </div>
        <div className="live-label">Live Page</div>
      </div>

      <div className="live-page-banner">
        <Banner type={"live"} view={"carousel"} />

        <Banner type={"live-gaming"} view={"carousel"} />
      </div>

      {showTester && (
        <div className="tester-container">
          <Button
            className="test-banner-button"
            disableRipple
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={onFilePicker}
            />
            <div>Upload Banner</div>
          </Button>

          <div className="live-page-banner">
            <div className="preview">Preview</div>

            <div id="banner-subcomponent">
              <div className="carousel-container border-radius">
                <swiper-container
                  class="swiper-container border-radius"
                  navigation="false"
                  pagination="true"
                  scrollbar="false"
                  // loop="true"
                  speed="300"
                  autoplay-delay="2500"
                >
                  {liveImageArray?.map((banner, index) => (
                    <swiper-slide
                      class="banner-container"
                      onClick={() => onOpenLink(banner)}
                      key={index}
                    >
                      <img
                        className="banner-image border-radius"
                        src={banner?.asset_url}
                      />
                      <div
                        className="tester-livestream-timer-container"
                        style={{
                          color: banner?.countdown_font_color,
                        }}
                      ></div>
                    </swiper-slide>
                  ))}
                </swiper-container>
              </div>
            </div>

            <div>Backend Use only</div>

            <div className="banner-info-container">
              {liveImageArray?.map((banner, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {`Banner ${index + 1}`}
                  </AccordionSummary>

                  <AccordionDetails>
                    <div className="banner-data-info-container">
                      <div className="banner-data-label-container">
                        {/* Is Countdown Required */}
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                className="custom-switch"
                                onChange={(event) =>
                                  onToggleIsCountdownRequired(event, index)
                                }
                                label="is_countdown_required"
                              />
                            }
                            label="is_countdown_required"
                          />
                        </FormGroup>

                        {/* CTA */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.cta}
                            onChange={(event) =>
                              onInputChange(event, index, "cta")
                            }
                            label="cta"
                            variant="outlined"
                          />
                        </div>

                        {/* Reference */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.reference}
                            onChange={(event) =>
                              onInputChange(event, index, "reference")
                            }
                            label="reference"
                            variant="outlined"
                          />
                        </div>

                        {/* Target Audience */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.targeted_audiences}
                            onChange={(event) =>
                              onInputChange(event, index, "targeted_audiences")
                            }
                            label="targeted_audiences"
                            variant="outlined"
                          />
                        </div>

                        {/* Title Font Color */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.title_font_color}
                            onChange={(event) =>
                              onInputChange(event, index, "title_font_color")
                            }
                            label="title_font_color"
                            variant="outlined"
                          />
                        </div>

                        {/* Type */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.type}
                            onChange={(event) =>
                              onInputChange(event, index, "type")
                            }
                            label="type"
                            variant="outlined"
                          />
                        </div>

                        {/* URL */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.url}
                            onChange={(event) =>
                              onInputChange(event, index, "url")
                            }
                            label="url"
                            variant="outlined"
                          />
                        </div>

                        {/* Countdown Font Color */}
                        <div className="banner-data-label">
                          <TextField
                            className="banner-tester-text-field"
                            value={banner?.countdown_font_color}
                            onChange={(event) =>
                              onInputChange(
                                event,
                                index,
                                "countdown_font_color"
                              )
                            }
                            label="countdown_font_color"
                            variant="outlined"
                          />
                        </div>

                        {/* Simple View */}
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                className="custom-switch"
                                onChange={(event) =>
                                  onToggleSimpleView(event, index)
                                }
                                label="Simple View"
                              />
                            }
                            label="Simple View"
                          />
                        </FormGroup>

                        {!banner?.simple_view && (
                          <div className="countdown-data-container">
                            {/* Countdown Start At */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={banner?.countdown_start_at}
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_start_at"
                                  )
                                }
                                label="countdown_start_at"
                                variant="outlined"
                              />
                            </div>

                            {/* Countdown Start At Epoch */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={banner?.countdown_start_at_epoch}
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_start_at_epoch"
                                  )
                                }
                                label="countdown_start_at_epoch"
                                variant="outlined"
                              />
                            </div>

                            {/* Countdown End At */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={banner?.countdown_end_at}
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_end_at"
                                  )
                                }
                                label="countdown_end_at"
                                variant="outlined"
                              />
                            </div>

                            {/* Countdown End At Epoch */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={banner?.countdown_end_at_epoch}
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "countdown_end_at_epoch"
                                  )
                                }
                                label="countdown_end_at_epoch"
                                variant="outlined"
                              />
                            </div>
                          </div>
                        )}

                        {banner?.simple_view && (
                          <div className="countdown-data-container">
                            {/* Manual Timer text */}
                            <div className="banner-data-label">
                              <TextField
                                className="banner-tester-text-field"
                                value={banner?.manual_timer_text}
                                onChange={(event) =>
                                  onInputChange(
                                    event,
                                    index,
                                    "manual_timer_text"
                                  )
                                }
                                label="Manual timer text"
                                variant="outlined"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="banner-data-button-container">
                        <CloseIcon
                          className="remove-button"
                          onClick={() => onRemoveBanner(index)}
                        />
                        <SaveIcon
                          className="save-button"
                          onClick={() => onSaveBanner(index)}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LivePageBannerTester;
