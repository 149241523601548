// General
import "./out-of-coins-dialog.scss";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  updateCoinsPackageDialog,
  updateOutOfCoinsDialog,
} from "../../../../redux/store/dialogStore";
// Material UI
import { Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// i18next
import { useTranslation, Trans } from "react-i18next";
// Custom Hooks
import IconManager from "../../../utility/manager/icon-manager/icon-manager";

const OutOfCoinsDialog = () => {
  // Redux variables
  const outOfCoinsDialog = useSelector(
    (state) => state.dialog.outOfCoinsDialog
  );
  const dispatch = useDispatch();

  // i18next variables
  const { t } = useTranslation();

  // Custom Hooks Functions
  const getIcon = IconManager();

  // Event Handlers | Button
  const onTopUp = () => {
    dispatch(updateCoinsPackageDialog(true));
  };

  // Event Handlers | MUI Dialog
  const onCloseDialog = () => {
    dispatch(updateOutOfCoinsDialog(false));
  };

  return (
    <Dialog
      className="custom-radius10-dialog"
      open={outOfCoinsDialog}
      onClose={onCloseDialog}
    >
      <div id="out-of-coins-dialog">
        <div className="dialog-header">
          <div className="close-button-container">
            <CloseIcon className="close-button" onClick={onCloseDialog} />
          </div>
        </div>

        <div className="out-of-coins-container">
          <div className="out-of-coins-padding-container">
            <div className="coins-background-container">
              {getIcon("outOfCoinsImage", "out-of-coins-image")}
            </div>

            <div className="out-of-coins-text-container">
              <div className="out-of-coins-title">
                <Trans
                  i18nKey={"1on1.out_of_coins_title"}
                  components={{ br: <br /> }}
                />
              </div>

              <div className="out-of-coins-description">
                {t("1on1.out_of_coins_desc", { coins: 1000 })}
              </div>
            </div>

            <div className="top-up-now-button" onClick={onTopUp}>
              {t("1on1.joining_call_cta_top_up_now")}!
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default OutOfCoinsDialog;
