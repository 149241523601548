const routeConst = {
  // General
  landing: { name: "Landing", path: "/" },
  aboutUs: { name: "About Us", path: "/about-us" },
  terms: {
    name: "Terms",
    path: "/terms",
    pathAlt: "https://sugarbook.me/terms",
    pathAltZhTw: "https://sugarbook.me/terms?lang=zh-tw",
    pathAltJaJp: "https://sugarbook.me/terms?lang=ja-jp",
  },
  privacy: {
    name: "Privacy",
    path: "/privacy-policy",
    pathAlt: "https://sugarbook.me/privacy",
    pathAltZhTw: "https://sugarbook.me/privacy?lang=zh-tw",
    pathAltJaJp: "https://sugarbook.me/privacy?lang=ja-jp",
  },
  // Login
  login: { name: "Login", path: "/login" },
  forgotPassword: { name: "Forgot Password", path: "/forgot-password" },
  // Sign Up
  join: { name: "Join", path: "/join" },
  phoneAuth: { name: "Phone Auth", path: "/phone-auth" },
  phoneAuthentication: {
    name: "Phone Authentication",
    path: "/phone-authentication",
    altPath: "/missing/phone-authentication",
  },
  verifyPhone: { name: "Verify Phone", path: "/verify-phone" },
  accountSignup: { name: "Account Signup", path: "/account-signup" },
  introductionSignup: {
    name: "Introduction Signup",
    path: "/introduction-signup",
  },
  usernameSignup: { name: "Username Signup", path: "/username-signup" },
  usernameSignupComplete: {
    name: "Username Signup Complete",
    path: "/username-signup-complete",
  },
  infoSignup: { name: "Info Signup", path: "/info-signup" },
  torSignup: { name: "TOR Signup", path: "/tor-signup" },
  descriptionSignup: {
    name: "Description Signup",
    path: "/description-signup",
  },
  detailSignup: { name: "Detail Signup", path: "/detail-signup" },
  detailsSignup: { name: "Details Signup", path: "/details-signup" }, // New
  premiumBenefits: {
    name: "Premium Benefits",
    path: "/premium-benefits",
    upgrade: {
      name: "Premium Benefit Upgrade",
      path: "/premium-benefits/upgrade",
    },
  },
  lifestyleSignup: { name: "Lifestyle Signup", path: "/lifestyle-signup" },
  signupSuccess: { name: "Signup Success", path: "/signup-success" },
  emailVerification: {
    name: "Email Verification",
    path: "/email-verification",
  },
  // Missing Details
  missingDetails: {
    name: "Missing Details",
    path: "/missing-details",
  },
  // Verification
  verify: {
    // Email Verification
    emailVerification: {
      name: "Email Verification",
      path: "/verify/email-verification",
      altPath: "/missing/email-verification",
    },
    // Mobile Verification
    mobileVerification: {
      name: "Mobile Verification",
      path: "/verify/mobile-verification",
      altPath: "/missing/mobile-verification",
    },
    // Profile Verification
    profileVerification: {
      name: "Profile Verification",
      path: "/verify/profile-verification",
    },
    // Sugarbook Verified
    sugarbookVerified: {
      name: "Sugarbook Verified",
      path: "/verify/sugarbook-verified",
      status: {
        name: "Sugarbook Verified Status",
        path: "/verify/sugarbook-verified/status",
        approved: {
          name: "Sugarbook Verified Status Approved",
          path: "/verify/sugarbook-verified/status/approved",
        },
        rejected: {
          name: "Sugarbook Verified Status Rejected",
          path: "/verify/sugarbook-verified/status/rejected",
          duplicate: "/verify/sugarbook-verified/status/duplicate",
        },
      },
    },
    // Customer Support Info
    customerSupportInfo: {
      mobile: {
        name: "Customer Support Info",
        path: "/verify/mobile/customer-support-info",
      },
      facial: {
        name: "Customer Support Info",
        path: "/verify/sugarbook-verified/customer-support-info",
      },
    },
  },
  // Upgrade
  upgrade: {
    name: "Upgrade",
    path: "/upgrade",
    checkout: {
      name: "Upgrade Checkout",
      path: "/upgrade/checkout",
    },
    payment: {
      name: "Upgrade Payment",
      path: "/upgrade/payment",
    },
    status: {
      name: "Upgrade Status",
      path: "/upgrade/status",
    },
    redirect: {
      name: "Upgrade Redirect",
      path: "/upgrade-redirect",
    },
  },
  // Payment
  payment: {
    name: "Payment",
    path: "/payment",
    add: {
      name: "Add Payment",
      path: "/payment/add",
    },
    redirect: {
      name: "Payment Redirect",
      path: "/payment/redirect",
    },
  },
  // Search
  search: { name: "Search", path: "/search" },
  searchFilter: { name: "Search Filter", path: "/search-filter" },
  // Inbox
  inbox: {
    name: "Inbox",
    path: "/inbox",
    messaging: {
      chat: {
        chatId: {
          name: "Messaging",
          path: "/inbox/messaging/chat/:id",
          clearPath: "/inbox/messaging/chat/",
        },
      },
    },
  },
  // Live
  live: {
    name: "Live",
    path: "/live",
    liveId: { name: "Live", path: "/live/:id", clearPath: "/live/" },
    livestreamer: { name: "Livestreamer", path: "/live/livestreamer" },
    ended: { name: "Live Ended", path: "/live/ended" },
  },
  // Interest
  interest: { name: "Interest", path: "/interests" },
  // Profile
  profile: {
    name: "Profile",
    path: "/profile",
    overview: { name: "Profile Overview", path: "/profile/overview" },
    view: {
      name: "Profile View",
      path: "/profile/view/:id",
      clearPath: "/profile/view/",
    },
    streamerCenter: {
      name: "Streamer Center",
      path: "/profile/streamer-center",
      lastStreamSummary: {
        name: "Last Stream Summary",
        path: "/profile/streamer-center/last-stream-summary",
      },
      follows: {
        name: "Follows",
        path: "/profile/streamer-center/follows",
      },
    },
    edit: { name: "Profile Edit", path: "/profile/edit" },
    wallet: { name: "Wallet", path: "/profile/wallet" },
    leveling: { name: "Leveling", path: "/profile/leveling" },
    completion: {
      name: "Profile Completion",
      path: "/profile/completion",
      datingStyle: {
        name: "Dating Style",
        path: "/profile/completion/dating-style",
      },
    },
  },
  // Settings
  settings: {
    name: "Settings",
    path: "/settings",
    updatePassword: {
      name: "Update Password",
      path: "/settings/update-password",
    },
    updateEmail: {
      name: "Update Email",
      path: "/settings/update-email",
      verification: {
        name: "Update Email Verification",
        path: "/settings/update-email/verification",
      },
    },
    blockedMembers: {
      name: "Blocked Members",
      path: "/settings/blocked-members",
    },
    photoPermission: {
      name: "Photo Permission",
      path: "/settings/photo-permission",
    },
    videoCall: {
      name: "Video Call Settings",
      path: "/settings/video-call",
      customize: {
        name: "Video Call Settings",
        path: "/settings/video-call/customize",
      },
    },
    deactivateAccount: {
      name: "Deactivate Account",
      path: "/settings/deactivate-account",
    },
  },
  // Video Call
  videoCall: {
    name: "Video Call",
    path: "/video-call",
    room: {
      name: "Video Call Room",
      path: "/video-call/room",
    },
    privateStandby: {
      name: "Private Standby",
      path: "/video-call/private-standby",
    },
    summary: {
      name: "Summary",
      path: "/video-call/summary",
    },
    rate: {
      name: "Rate",
      path: "/video-call/rate",
    },
    callHistory: {
      name: "Call History",
      path: "/video-call/call-history",
    },
  },
  // Feed
  feed: {
    name: "Feed",
    path: "/feed",
  },
  // Leaderboard
  leaderboard: {
    name: "Leaderboard",
    path: "/leaderboard",
    list: {
      name: "Leaderboard Livestreaming List",
      path: "/leaderboard/list",
    },
  },
  // Support
  support: { name: "Support", path: "/support" },
  // Blogs
  blogs: { name: "Blogs", path: "https://sugarbook.me/blog/" },
  // Events
  events: {
    name: "Events",
    path: "https://sugarbook.me/events/livestream",
  },
  // Safety Tips
  safetyTips: {
    name: "Safety Tips",
    path: "/safety-tips",
    pathAlt: "https://sugarbook.me/safety-tips",
  },
  // Tutorials
  tutorials: {
    name: "Tutorials",
    path: "/tutorials",
    videoCall: { name: "Video Call Tutorial", path: "/tutorials/video-call" },
  },
  // Report
  report: {
    name: "Report",
    path: "/report",
  },
  // Coin Shop
  coinShop: {
    name: "Coin Shop",
    path: "/coin-shop",
  },
  // Games
  games: {
    name: "Games",
    path: "/games",
    casino: {
      name: "Casino Games",
      path: "/games/casino",
    },
  },
  // Logout
  logout: {
    name: "Logout",
    path: "/logout",
  },
  // Redirect
  redirectHandler: {
    name: "Redirect Handler",
    path: "/redirect_handler",
  },
  // Zendesk
  zendesk: {
    name: "Zendesk",
    path: "https://sugarbook.zendesk.com/hc/en-us",
    streamerPolicy: {
      name: "Streamer Policy",
      path: "https://sugarbook.zendesk.com/hc/en-us/categories/4403383232921-Streamer-Rewards-Rules-Policy",
    },
    premiumCoinPackage: {
      name: "Premium Coin Package",
      path: "https://sugarbook.zendesk.com/hc/en-us/sections/4403345079449-Premium-Coins-Package",
    },
    guideToLivestreaming: {
      name: "A Guide to Livestreaming",
      path: "https://sugarbook.zendesk.com/hc/en-us/sections/4403356790041-A-Guide-to-Livestreaming",
    },
    diamondsWithdrawal: {
      name: "Diamonds Withdrawal",
      path: "https://sugarbook.zendesk.com/hc/en-us/articles/4403662305561-I-want-to-cash-out-my-diamonds",
    },
  },
  // Social Media
  socialMedia: {
    line: {
      name: "Line",
      path: "https://line.me/R/ti/p/@sugarbookvip",
    },
    telegram: {
      name: "Telegram",
      path: "https://t.me/joinchat/oBsvsFNTd1djOTk9",
    },
    sbPrTeamTelegram: {
      name: "SB PR Team Telegram",
      path: "https://t.me/SBPRTEAM",
    },
  },
  // Misc
  misc: {
    levellingLite: {
      name: "Levelling Lite",
      path: "https://sugarbook.me/levelling_lite",
      pathZhTw: "https://sugarbook.me/levelling_lite?lang=zh-tw",
    },
    sugarbookAndroidApk: {
      name: "Sugarbook Android APK",
      path: "https://sugarbook.me/apkdownload",
    },
    sugarbookIosApp: {
      name: "Sugarbook iOS App",
      path: "https://sugarbook.net/my/blog/join-livestreaming-sb-ios/",
    },
  },
  // Test
  superSecret: {
    name: "Super Secret",
    path: "/super-secret",
    bannerTester: {
      name: "Banner Tester",
      path: "/super-secret/banner-tester",
    },
  },
};

export default routeConst;
